import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import {useNavigate} from "react-router-dom";
import {usePagination} from "UI/App/Components/Pagination/Pagination";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Table from "UI/App/Components/Table/Table";
import Icon from "UI/App/Components/Icon/Icon";
import Popup, {closePopup, openPopup} from "UI/App/Components/Popup/Popup";
import CreateWareHouse from "UI/App/Partials/Content/Warehouse/CreateWareHouse";
import HasRole from "UI/App/Components/Auth/HasRole";


export default function WareHouses() {
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    const [wareHouseTableData, setWareHouseTableData] = useState([]);

    const {
        paging,
        filtering,
        setTotalPages,
        setTotalResults,
        filterQuery
    } = usePagination({
        storageKey: 'warehouse',
        searchSettings: {
            enabled: true,
            strapiFields: ['name', 'location']
        },
        htmlElements: [
            <HasRole roles={['admin']}>
                <span className='pagination__filter addItem' key='link--add'>
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    openPopup("addWarehouse")
                }} className='btn btn--add btn--icon-right'>Toevoegen <Icon name='plus'/></span>
            </span>
            </HasRole>,
        ],
    });

    const query = stringify({
        sort: ['id:desc']
    }, { encodeValuesOnly: true});

    useEffect(() => {
        getWareHouses();
    }, [filterQuery]);

    function getWareHouses(){
        authAxios
            .get(`/logistics/warehouses?${query}&${filterQuery}`)
            .then(({data}) => {
                const tableData = [];

                // loop through all the records
                for (const [index, wareHouse] of Object.entries(data.data)) {

                    // add a row for the warehouse
                    tableData.push({
                        attributes: {
                            onClick: (e) => {
                                navigate(`/warehouses/${wareHouse.id}`);
                            }
                        },
                        data: [
                            wareHouse.name,
                            wareHouse.location,
                        ]
                    });
                }

                setWareHouseTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Kon de magazijnen niet ophalen.');
            });
    }

    const doneCreating = () => {
        closePopup('addWarehouse');
        getWareHouses();
    }

    return (
        <>
            <Popup popupID='addWarehouse' title={'Magazijn toevoegen'}>
                <CreateWareHouse closePopup={doneCreating}/>
            </Popup>
            {filtering}
            <Table
                headers={['Naam', 'Locatie']}
                data={wareHouseTableData}
                padding={'s'}
            />
            {paging}
        </>
    );
}
