import Select from "react-select";
import {getSelectedValue} from "App/Util/select2";
import {useContext, useEffect, useState} from "react";
import {FetchContext} from "App/Strapi/FetchContext";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";

export default function MaterialPopupContent({
    onSubmit,
    defaultMaterialId,
    defaultUnit,
    defaultWarehouse,
    defaultQuantity,
    maximumQuantity,
    buttonText,
    materialLocked,
    displayAllWarehouses,
}) {
    const { authAxios } = useContext(FetchContext);

    const [materials, setMaterials] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehousesLoaded, setWarehousesLoaded] = useState(false);

    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedQuantity, setSelectedQuantity] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    /**
     * Get the materials from the API
     */
    useEffect(() => {
        authAxios
            .get('/logistics/materials/all')
            .then(({data}) => {
                const materials = data
                    .filter(material => material.active === true)
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map(material => ({
                        category: material.category,
                        value: material.id,
                        label: material.calculationDescription,
                    }));

                const categories = materials
                    .map(material => material.category)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort();

                setMaterials(
                    categories.map(category => ({
                        label: category,
                        options: materials
                            .filter(material => material.category === category)
                            .sort((a, b) => a.label.localeCompare(b.label))
                    }))
                );
            }).catch((exception) => {
                console.error(exception);
            });
    }, [authAxios]);

    useEffect(() => {
        loadDefaults();
    }, [defaultMaterialId, defaultUnit, defaultQuantity, defaultWarehouse]);

    async function loadDefaults(){
        if (defaultMaterialId) {
            setSelectedMaterial(defaultMaterialId);
            setSelectedUnit( await (new MaterialUnitMutator()).obtainUnit(defaultMaterialId));
        }

        if (defaultQuantity) {
            setSelectedQuantity(defaultQuantity);
        }

        if (defaultWarehouse) {
            setSelectedWarehouse(defaultWarehouse);
        }
    }

    useEffect(() => {
        if (!selectedMaterial) return;
        updateSelectedMaterial(selectedMaterial);
    }, [authAxios, selectedMaterial, defaultMaterialId])

    async function updateSelectedMaterial(selectedMaterial) {
        // Get material unit
        let materialUnit = await (new MaterialUnitMutator()).obtainUnit(selectedMaterial);

        // Set select unit type
        setSelectedUnit(materialUnit);

        setWarehousesLoaded(false);

        if (displayAllWarehouses) {
            authAxios.get(`/logistics/warehouses/all`).then(({data}) => {
                setWarehouses(
                    data.map(warehouse => ({
                        value: warehouse.id,
                        label: warehouse.name,
                    }))
                );
            }).catch((exception) => {
                console.error(exception);
            }).finally(() => {
                setWarehousesLoaded(true);
            });
        } else {
            authAxios.get(`/logistics/materials/${selectedMaterial}/stock`).then(({data}) => {
                const wareHouses = data.data.stock
                    .filter(stockItem => stockItem.total !== null)
                    .map(stockItem => ({
                        value: stockItem.warehouse.id,
                        label: stockItem.warehouse.name,
                        unit: stockItem.unit,
                    }))
                    .filter((value, index, self) => self.findIndex(item => item.value === value.value) === index)

                setWarehouses(wareHouses);
            }).catch((exception) => {
                console.error(exception);
            }).finally(() => {
                setWarehousesLoaded(true);
            });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        onSubmit(selectedMaterial, selectedUnit, selectedQuantity, selectedWarehouse);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='input-group'>
                <label htmlFor='material'>Materiaal</label>
                <Select
                    id='material'
                    name='material'
                    value={getSelectedValue(selectedMaterial, materials)}
                    onChange={(material) => setSelectedMaterial(material.value)}
                    isMulti={false}
                    isClearable
                    options={materials}
                    isLoading={materials?.length === 0}
                    isDisabled={materialLocked}
                />
            </div>

            <div className='input-group'>
                <label htmlFor='quantity'>Aantal {selectedUnit}</label>
                <input
                    id='quantity'
                    type='number'
                    max={maximumQuantity}
                    placeholder='Aantal'
                    value={(selectedQuantity ?? '')}
                    onChange={(event) => setSelectedQuantity(event.target.value === '' ? '' : Number(event.target.value))}
                    name={'quantity'}
                />
            </div>

            <div className="input-group">
                <label htmlFor='warehouse'>Magazijn</label>
                <Select
                    id='warehouse'
                    value={getSelectedValue(selectedWarehouse, warehouses)}
                    onChange={(warehouse) => setSelectedWarehouse(warehouse.value)}
                    isMulti={false}
                    isClearable
                    options={warehouses}
                    noOptionsMessage={() => 'Geen magazijnen gevonden waarin dit materiaal beschikbaar is.'}
                    isLoading={!warehousesLoaded}
                />
            </div>

            <div>
                <button style={{width: '100%'}} className='btn--primary'>{buttonText}</button>
            </div>
        </form>
    )
}
