import Block from "../../../Components/Block/Block";
import {useContext, useEffect, useState} from "react";
import './sticker.scss';
import Select from "react-select";
import {jsPDF} from "jspdf";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {closePopup} from "UI/App/Components/Popup/Popup";
import {stringify} from "qs";
import {useFileUpload} from "UI/App/Components/Form/FileUpload/FileUpload";
import Boolean from "UI/App/Components/Form/Boolean";
import IF from "UI/App/Components/Conditional/IF";
import BarcodeGenerator from 'UI/App/Components/Barcode/BarcodeGenerator';
import ReactQuill from "react-quill";
import {Spinner} from "UI/App/Components/Spinner";


const _TYPE_TEXT = false;
const _TYPE_FILE = true;

export default function CreateSticker({ order = null, setOrder = () => {} }) {

    const [content, setContent] = useState('');
    const [name, setName] = useState('');

    const [height, setHeight] = useState('50');
    const [width, setWidth] = useState('50');
    const [orientation, setOrientation] = useState('p');
    const [stickerType, setStickerType] = useState(_TYPE_TEXT);
    const [base64, setBase64] = useState(undefined)
    const [barcodeValue, setBarcodeValue] = useState(undefined)

    const [isStoring, setIsStoring] = useState(false);

    const fetchContext = useContext(FetchContext);
    const [sizes, setSizes] = useState([
        {
            label: '54x101mm (liggend)',
            value: '54x101'
        }, {
            label: '159x104mm (staand)',
            value: '159x104'
        }, {
            label: '104x159mm (liggend)',
            value: '104x159'
        }
    ]);

    const [size, setSize] = useState({
        label: '54x101mm',
        value: '54x101'
    });

    const {
        FileUpload,
        attachment,
    } = useFileUpload(
        // Accept images and pdf
        'image/*,.pdf',
        'Sticker uploaden',
        'file',
        'Sticker bekijken'
    )


    // Handle size change
    useEffect(() => {
        //Get width/height
        setHeight(size.value.split('x')[0])
        setWidth(size.value.split('x')[1])
        setOrientation(size.label.includes('staand') ? 'p' : 'l')

        // Load default content to sticker
        addDefaultContent();
    }, [size, order])

    useEffect(() => {

        // debounce the qr/bar code generator
        const timeoutId = setTimeout(() => {
            // between '**' and '**' find 12 or less numbers, split if more then 12 numbers and capture.
            const codeValue = RegExp(/(?:\*{2})(\d{1,12}(\d*))(?:\*{2})/).exec(content)

            // do something with our 12 numbers
            if (codeValue?.[1] !== undefined) {
                if (codeValue?.[2]?.length > 0) {
                    let shortendValue = codeValue[1].slice(0, -(codeValue?.[2].length))
                    setBarcodeValue(shortendValue.padEnd(12, "0"))
                } else {
                    setBarcodeValue(codeValue[1].padEnd(12, "0"))
                }

            } else {
                setBarcodeValue(undefined)
            }

            // we have more then 12 numbers, thats an ivalid EAN13 code.
            if (codeValue?.[2]?.length > 0) {
                // replace numbers between '**' and '**' with a valid amount
                let ean13CompliantContent = content.replace(/(?!\*{2})(\d*)(?=\*{2})/, codeValue[1].slice(0, -(codeValue?.[2].length)))
                // save content with an EAN13 valid barcode
                setContent(ean13CompliantContent)
            }
        }, 330);

        return () => clearTimeout(timeoutId);

    }, [content])

    function addDefaultContent() {
        setContent(`${order?.company?.name}\n${order?.description ?? ''}\n${order?.chosenAmount} exemplaren`)
    }

    function makeItalic(doc, text, offset) {
        // check if we need to line break    
        let newLineProgress = offset.startX / (width - 20)
        if (newLineProgress >= 0.85) {
            offset.rowOffset += offset.newlineSpaceing
            offset.startX = 10;
        }
        doc.text('', offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'italic');

        let widthOffset = doc.getTextWidth(text);
        // set text to bold
        doc.text(text, offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'normal');
        offset.startX += widthOffset

    }

    function makeBold(doc, text, offset) {
        // check if we need to line break    
        let newLineProgress = offset.startX / (width - 20)
        if (newLineProgress >= 0.85) {
            offset.rowOffset += offset.newlineSpaceing
            offset.startX = 10;
        }
        doc.text('', offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'bold');

        let widthOffset = doc.getTextWidth(text);
        // set text to bold
        doc.text(text, offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'normal');
        offset.startX += widthOffset

    }

    function makeBoldItalic(doc, text, offset) {
        // check if we need to line break    
        let newLineProgress = offset.startX / (width - 20)
        if (newLineProgress >= 0.85) {
            offset.rowOffset += offset.newlineSpaceing
            offset.startX = 10;
        }
        doc.text('', offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'bolditalic');

        let widthOffset = doc.getTextWidth(text);
        // set text to bold
        doc.text(text, offset.startX, 10 + offset.rowOffset).setFont(offset.fontName, 'normal');
        offset.startX += widthOffset

    }

    function makeNormal(doc, payload, offset) {
        let results = doc.splitTextToSize(payload, width - (20 + offset.startX))
        // if the result still fits on current line, add it to current line
        if (results.length <= 1) {
            doc.text(payload, offset.startX, 10 + offset.rowOffset, {
                maxWidth: width - 20,
            })

            let widthOffset = doc.getTextWidth(payload);
            offset.startX += widthOffset
        } else { // if te result add more then what we want DONT add it to current line
            // print the first entry with x offset
            doc.text(results[0], offset.startX, 10 + offset.rowOffset, {
                maxWidth: width - 20,
            })
            offset.rowOffset += offset.newlineSpaceing

            // after the ellement WITH x offset has been printed we can print the rest 'cleanly'
            // re-oder list without the printed element
            results.shift()

            // re-join list to make splitTextToSize work
            results = results.join(" ")

            // print the rest with splitTextTosize
            doc.text(doc.splitTextToSize(results, width - 20), 10, 10 + offset.rowOffset, {
                maxWidth: width - 20,
            })

            // keep track of the printed rows
            offset.rowOffset += offset.newlineSpaceing * doc.splitTextToSize(results, width - 20).length
            offset.startX = 10
        }
    }

    function splitOnType(doc, g2, offset) {
        let isBold = false;
        let isItalic = false;
        let isNewLine = false;

        // take care of start text before styling
        g2 = g2.replace(/^.*?(?=<)/g, function (match, _g1, _g2, _g3) {
            offset.startX = 10
            makeNormal(doc, match, offset)
            return ""
        })

        // take care of styling
        g2 = g2.replace(/<[^>]*?>[^<]*/g, function (match, _g1, _g2, _g3) {
            let type = match.match(/<[^>]*?>/)[0]
            let payload = match.replace(type, "")

            // check if bold should start/end
            if (type === "<strong>") {
                isBold = true;
            } else if (type === "</strong>") {
                isBold = false;
            }

            // check if italic should start/end
            if (type === "<em>") {
                isItalic = true;
            } else if (type === "</em>") {
                isItalic = false;
            }

            // check if newline is present
            if (type === "<br>") {
                offset.rowOffset += offset.newlineSpaceing
                offset.startX = 10;
                isNewLine = true;
            }

            // handle text based on styling
            if (isBold && isItalic) {
                makeBoldItalic(doc, payload, offset);
            } else if (isBold) {
                makeBold(doc, payload, offset);
            } else if (isItalic) {
                makeItalic(doc, payload, offset);
            } else if (!isBold && !isItalic && payload !== "") {
                makeNormal(doc, payload, offset)
            }

            return ""
        })

        // take care of 'normal' text
        if (g2 !== "") {
            doc.text(doc.splitTextToSize(`${g2}`, width - 20), 10, 10 + offset.rowOffset, {
                maxWidth: width - 20,
            })
            offset.rowOffset += offset.newlineSpaceing * doc.splitTextToSize(`${g2}`, width - 20).length
            offset.startX = 10;
        } else if (!isNewLine) {// if this line is NOT a new line add a line (end of line)
            offset.rowOffset += offset.newlineSpaceing
        }

    }

    function getPDF(open = false) {
        const doc = new jsPDF(orientation, 'mm', [height, width]);
        let offsets = {
            startX: 10,
            rowOffset: 0,
            newlineSpaceing: 7,
            fontName: "helvetica"
        }

        let fontSize = 14
        doc.setFontSize(fontSize);
        doc.setFont('helvetica', 'normal');
        doc.setLineHeightFactor(1.3);

        // remove bar/qr markup from the content
        let cleanContent = content
            .replace(/(\*{2})(\d*)(\*{2})/g, "")
            .replace(/(&amp;)/g, "&")

        // split content on 'lines' which are wrapped in <p></p>
        cleanContent.replace(/((?:<p[^>]*?>)(.*?)(?:<\/p>)+)/g, function (match, g1, g2, g3) {
            splitOnType(doc, g2, offsets)
        })

        // Add order number
        doc.setFontSize(13);
        doc.text(`${order.number}`, width - 10, height - 10, { align: 'right', maxWidth: width - 20 });

        // add base64 of barcode
        if (base64 !== undefined) {
            // add image 10 pixels from the left, and '10' form the bottom
            doc.addImage(base64, 'png', 10, height - 20, 50, 12);
        }

        if (open) {
            doc.output('dataurlnewwindow');
        }
        return window.btoa(doc.output());
    }

    function storeSticker() {
        setIsStoring(true);
        const query = stringify({
            populate: {
                order: {
                    populate: {
                        company: true,
                        contactPerson: true,
                        calculation: true,
                        quote: true,
                        stickers: {
                            sort: ['id:desc'],
                            populate: {
                                file: {
                                    fields: ['id', 'name', 'mime']
                                }
                            }
                        },
                        packingSlips: {
                            fields: ['id', 'number'],
                            sort: ['id:desc']
                        }
                    }
                }
            }
        });

        if (name === '') {
            toast.error('Naam is een verplicht veld')
            return;
        }

        const formData = new FormData();

        formData.append('data', JSON.stringify({
            Name: name,
            order: order.id,
            base64: getPDF(),
            Content: content
        }));

        if (attachment?.file) {
            formData.append('files.file', attachment.file, attachment.file.name);
        }

        fetchContext.authAxios.post(`/stickers?${query}`, formData).then((response) => {
            // Update order
            setOrder(response.data.data.order)

            //add toast
            toast.success('Sticker opgeslagen!');
            closePopup('addSticker')

        }).catch((e) => {
            toast.error('Er ging iets mis met melding: \n' + e.response.data.error.message);

            console.error(e);
        }).finally(() => {
            setIsStoring(false);
        });
    }

    function insertTab(e) {
        if (e.key === 'Tab') {
            e.preventDefault();
            let start = e.target.selectionStart;
            let end = e.target.selectionEnd;

            // set textarea value to: text before caret + tab + text after caret
            e.target.value = e.target.value.substring(0, start) +
                "\t" + e.target.value.substring(end);

            // put caret at right position again
            e.target.selectionStart =
                e.target.selectionEnd = start + 1;
        }
    }

    return (
        <Block name="createSticker">
            <input
                placeholder='Naam'
                name='Name'
                value={name}
                onChange={(e) => {
                    setName(e.target.value)
                }}
                style={{
                    minWidth: '101mm',
                }}
            />

            <Boolean
                style={{
                    width: '100%',
                    marginBottom: '10px'
                }}
                field={{
                    name: 'text-or-file'
                }}
                displayTrue='Bestand'
                displayFalse='Tekst'
                falseIsRed={false}
                setValue={(name, value) => {
                    setStickerType(value)
                }}
                value={stickerType}
            />

            <IF condition={stickerType === _TYPE_TEXT}>
                <Select
                    name='size'
                    id='size'
                    isClearable={false}
                    options={sizes}
                    value={size}
                    onChange={(size) => {
                        setSize(size);
                    }}
                    required
                />

                {/* custom tool bar so it doesent take up height from the quill element */}
                <div id="toolbar">
                    <button class="ql-bold" style={{ height: "25px" }}>b</button>
                    <button class="ql-italic">i</button>
                </div>

                <ReactQuill
                    style={{
                        height: `${height}mm`,
                        width: `${width}mm`,
                    }}
                    modules={{
                        toolbar: {
                            container: "#toolbar"
                        }
                    }}
                    className="sticker__content"

                    value={content ?? ""}
                    onChange={(e) => {
                        setContent(e)
                    }}
                    onKeyDown={insertTab}
                />

                <span className="sticker__order__number" style={{
                    top: `${parseInt(height) + 42}mm`,
                    right: `${parseInt(width) - 60}mm`,
                }}>
                    <BarcodeGenerator
                        d1
                        height={"30px"}
                        width={"4px"}
                        fontSize={"48pt"}
                        zoom={0.50} // 'upscale' the image to create a crisper look
                        download={false}
                        displayValue={true}
                        setBase64={setBase64}
                        font={"Helvetica"}
                        fontOptions={""}
                        show={barcodeValue !== undefined}
                        value={barcodeValue}
                    />
                </span>

                <span className="sticker__order__number" style={{
                    top: `${parseInt(height) + 49}mm`,
                    left: `${parseInt(width) - 28}mm`,
                }}>
                    {order?.number}
                </span>
                <br/>
            </IF>

            <IF condition={stickerType === _TYPE_FILE}>
                <FileUpload />
                <br/>
            </IF>

            <button onClick={() => storeSticker()} disabled={isStoring}>
                <IF condition={isStoring}>
                    <Spinner white={true} width={15} height={15} />
                </IF>
                Opslaan
            </button>
        </Block>
    )
}
