import React from "react";
import PDF from "App/Util/print";
import {formatName, mmToPx} from "App/Util/format";
import {getNumLines} from "App/Util/getVisibleTextWidth";
import {formatDataForPDF} from "App/Util/pdf";

export default class QuotePDFGenerator extends React.Component {
    backgroundURL;
    rowData;
    chosenSupplier;
    chosenContactPerson;
    owner;
    authAxios;
    userinfo;
    date;
    showPdf;
    language;
    purNumber;
    quoteData;
    shippingAdres;
    shipment;
    deliveryTime;
    price;
    shippingCost;
    signature;
    placeOfOrigin;
    introLine;
    footerData;
    footerClosingText;
    endDate;
    sender;

    //selectedBackground, rowData, company, chosenContactPerson, false, owner, language,date
    constructor(selectedBackground, rowData, company, contactPerson, owner, showPdf = false, language = 'nl', date, signature, placeOfOrigin = '', introLine = '', footerData = [], footerClosingText = '', endDate = null, sender = undefined) {
        super();
        this.backgroundURL = selectedBackground;
        this.rowData = rowData;
        this.company = company;
        this.chosenContactPerson = contactPerson;
        this.owner = owner;
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.showPdf = showPdf;
        this.language = language;
        this.date = date;
        this.signature = signature;
        this.placeOfOrigin = placeOfOrigin;
        this.introLine = introLine;
        this.footerData = footerData;
        this.footerClosingText = footerClosingText;
        this.endDate = endDate;
        this.sender = sender;
    }

    async formatForPdf(data, preformatted = false) {

        return formatDataForPDF({
            language: this.language,
            data: data,
            company: this.company,
            contactPerson: this.chosenContactPerson,
            isDataPreFormatted: preformatted,
            signature: this.signature,
            headerRowCount: 8 + getNumLines((this.introLine ?? ''), '10pt helvetica', mmToPx(210 - 21 - 21)),
            footerRowCount: 8 + this.footerData.length + getNumLines((this.footerClosingText ?? ''), '10pt helvetica', mmToPx(210 - 21 - 21))
        });
    }

    async getPDF() {
        let lineHeight = 4;
        let currentlineHeight = 50;
        let paddingLeft = 20;
        let pageCutoffXCords = 295;
        let fontSize = 10;

        let contactPerson = this.chosenContactPerson// Add Contact person
        if (this.chosenContactPerson !== null) {
            contactPerson = this.chosenContactPerson;
        }


        /**
         * Add background
         */
        var doc = new PDF();

        if (this.backgroundURL !== null) {
            doc.addImage(this.backgroundURL.page1, 'JPEG', 0, 0, 210, 297);
        }

        doc.setFont('Helvetica', 'normal');
        // Add title
        doc.setFontStyle('bold');
        doc.setFontSize(fontSize);

        doc.text(this.language === 'nl' ? 'OFFERTE' : 'OFFER', paddingLeft, currentlineHeight);
        currentlineHeight += (lineHeight * 3);
        doc.setFont('Helvetica', 'normal');

        // Add supplier
        if (this.company !== null && this.company !== undefined) {

            if (this.company?.name) {
                doc.text(this.company?.name, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            if (contactPerson !== null) {
                doc.text(formatName(contactPerson?.firstName, contactPerson?.prefixToSurname, contactPerson?.surname), paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier addressLine1
            if (this.company?.address?.addressLine1) {
                doc.text(this.company?.address?.addressLine1, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier addressLine2
            if (this.company?.address?.addressLine2) {
                doc.text(this.company?.address?.addressLine2, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier addressLine3
            if (this.company?.address?.addressLine3) {
                doc.text(this.company?.address?.addressLine3, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }

            // supplier postal & city
            if (this.company?.address?.postalCode || this.company?.address?.city) {
                // double space is in original document
                doc.text(
                    `${this.company?.address?.postalCode ?? ''}  ${this.company?.address?.city ?? ''}`.trim().toUpperCase(),
                    paddingLeft,
                    currentlineHeight
                );
                currentlineHeight += lineHeight;
            }

            // supplier country
            if (this.company?.address?.country !== "" && this.company?.address?.country !== undefined) {
                console.log(this.company?.address?.country);
                doc.text(this.company?.address?.country, paddingLeft, currentlineHeight);
                currentlineHeight += lineHeight;
            }
        }

        currentlineHeight += (lineHeight * 2);

        let formattedDate = '';
        console.log(this.date);
        if (this.date !== null || this.date !== undefined) {
            const options = {year: 'numeric', month: 'long', day: 'numeric'};
            const locale = this.language === 'nl' ? 'nl-NL' : 'en-US';
            let date;

            if (this.date === null || this.date === undefined) {
                date = new Date();
            } else {
                date = new Date(this.date)
            }

            formattedDate = (date).toLocaleDateString(locale, options)

            if (this.placeOfOrigin !== null || this.placeOfOrigin !== undefined) {
                // the date
                doc.text(
                    `${this.placeOfOrigin}, ${formattedDate}`,
                    paddingLeft,
                    currentlineHeight
                );
                currentlineHeight += (lineHeight * 3);
            }
        }

        // pur number
        if (this.purNumber !== null && this.purNumber !== undefined) {
            if (this.language === 'nl') {
                doc.text(`Ons inkoopnummer`, paddingLeft, currentlineHeight)
                doc.text(`:`, paddingLeft + 45, currentlineHeight);
                doc.text(this.purNumber, paddingLeft + 50, currentlineHeight, {
                    maxWidth: 120
                });
            } else {
                doc.text(`Our reference`, paddingLeft, currentlineHeight)
                doc.text(`:`, paddingLeft + 45, currentlineHeight);
                doc.text(this.purNumber, paddingLeft + 50, currentlineHeight, {
                    maxWidth: 120
                });
            }

            currentlineHeight += (lineHeight * 3);
        }

        // Add Contact person
        if (this.chosenContactPerson !== null) {
            doc.text(`${this.language === 'nl' ? 'Beste' : 'Dear'} ${this.chosenContactPerson.firstName},`, paddingLeft, currentlineHeight);
            currentlineHeight += (lineHeight * 2);
        }

        if (this.introLine !== '') {
            doc.text(this.introLine,
                paddingLeft,
                currentlineHeight,
                {
                    maxWidth: 210 - 21 - 21
                }
            );
            const lineCount = getNumLines(this.introLine, '10pt helvetica', mmToPx(210 - 21 - 21))
            currentlineHeight += lineHeight + (lineHeight * lineCount);
        }

        // Add data rows
        let currentPage = 0;
        console.log(this.rowData);
        for (const rows of this.rowData) {

            //Add page after page 2
            if (currentPage !== 0) {
                currentlineHeight = 50;
                doc.addPage()
                if (this.backgroundURL !== null) {
                    doc.addImage(this.backgroundURL.page2, 'JPEG', 0, 0, 210, 297);
                }
            }


            for (const row of rows) {
                // Add a new page if the row doesn't fit properly on the current page
                const totalRowHeight = getNumLines(row?.content, '10pt helvetica', mmToPx(120)) * lineHeight;

                //First part of row
                doc.text(row.label, paddingLeft, currentlineHeight);

                // Only add spacer if label is set
                if (row.label !== '') {
                    // Add spacer
                    doc.text(`:`, paddingLeft + 45, currentlineHeight);
                }

                if (row.type === 'price' || row.type === 'HJMGPrice' || row?.displayType === 'price') {

                    const currencySymbol = row.content[0];
                    // Add euro sign
                    doc.text(currencySymbol, paddingLeft + 145, currentlineHeight, {
                        maxWidth: 10,
                    });
                    // Add value
                    doc.text(row.content.replace(currencySymbol, '').trim(), paddingLeft + 170, currentlineHeight, {
                        maxWidth: 120,
                        align: 'right'
                    });
                } else {
                    // Add value
                    doc.text(row.content, paddingLeft + 50, currentlineHeight, {
                        maxWidth: 120
                    });
                }

                currentlineHeight += totalRowHeight;
                currentlineHeight += 1;
            }
            currentPage++;
        }


        [doc, paddingLeft, currentlineHeight, lineHeight] = await this.addFooter(doc, paddingLeft, currentlineHeight, lineHeight)

        currentlineHeight += lineHeight;

        doc.text(this.language === 'nl' ? 'Met vriendelijke groet,' : 'Kind regards,', paddingLeft, currentlineHeight);

        currentlineHeight += lineHeight;

        if (this.owner === "pretore") {

            doc.text("Pretore B.V.", paddingLeft, currentlineHeight);
            currentlineHeight += lineHeight;
        }

        if (this.owner === "hjmg") {

            doc.text("HJ Media Groep B.V.", paddingLeft, currentlineHeight);
            currentlineHeight += lineHeight;
        }

        if (this.signature) {
            const prevlineHeight = currentlineHeight;

            const imageHeight = lineHeight * 4;

            // calculate if section needs to go to the next page
            const currentLineYPosisition = doc.addNewPageIfOverflown(currentlineHeight + imageHeight, 21, 50, this.backgroundURL);
            currentlineHeight = currentLineYPosisition > prevlineHeight ? prevlineHeight : currentLineYPosisition;

            // add the image to the correct lineHeight
            doc.addImage(this.signature, undefined, paddingLeft, currentlineHeight, 0, imageHeight);

            // set the currentlineHeight to below the image
            currentlineHeight += imageHeight + lineHeight;
        } else { // if we dont have a signature add an extra line of white space for a manual signature later on.
            currentlineHeight += lineHeight;
        }

        currentlineHeight += lineHeight;

        if (this.userInfo !== undefined) {
            doc.text(
                this.sender ?? `${this.userInfo.firstName} ${this.userInfo.lastName}`,
                paddingLeft,
                currentlineHeight
            );
        }

        if (this.showPdf) {
            doc.openPDF()
        }

        return window.btoa(doc.output());
    }

    async addFooter(doc, paddingLeft, currentlineHeight, lineHeight) {
        currentlineHeight += (lineHeight * 2);

        doc.setFont('Helvetica', 'bold');
        doc.text(this.language === 'nl' ? 'Leveringsvoorwaarden' : 'Terms and Conditions', paddingLeft, currentlineHeight, {
            maxWidth: 210 - 21 - 21
        });
        currentlineHeight += lineHeight;

        doc.setFont('Helvetica', 'normal');

        doc.text(
            this.language === 'nl' ? 'Geldig tot' : 'Offer valid',
            paddingLeft,
            currentlineHeight
        );
        doc.text(`:`, paddingLeft + 45, currentlineHeight);
        let formattedDate = '';
        if (this.endDate !== null) {
            const options = {year: 'numeric', month: 'long', day: 'numeric'};
            const locale = this.language === 'nl' ? 'nl-NL' : 'en-US';
            let date;

            if (this.endDate === null || this.endDate === undefined) {
                date = Date.now();
            } else {
                date = new Date(this.endDate)
            }

            formattedDate = (date).toLocaleDateString(locale, options)
        }
        doc.text(
            formattedDate,
            paddingLeft + 50,
            currentlineHeight
        );
        currentlineHeight += (lineHeight * 1);
        // Add footer rows
        for (const row of this.footerData) {
            // con 1
            doc.text(
                row.label,
                paddingLeft,
                currentlineHeight
            );
            doc.text(`:`, paddingLeft + 45, currentlineHeight);
            doc.text(
                row.content,
                paddingLeft + 50,
                currentlineHeight
            );
            currentlineHeight += (lineHeight * 1);
        }

        currentlineHeight += (lineHeight * 2);
        if (this.footerClosingText !== null) {
            doc.text(this.footerClosingText, paddingLeft, currentlineHeight, {
                maxWidth: 210 - 21 - 21
            });

            currentlineHeight += getNumLines(this.footerClosingText, '10pt helvetica', mmToPx(210 - 21 - 21)) * lineHeight;
        }

        return [doc, paddingLeft, currentlineHeight, lineHeight]
    }

    decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
        }

        return str;
    }


    numberOfTabs(text) {
        return (text.match(/\t/g) || []).length;
    }
}
