import {useCallback, useContext, useEffect, useState} from "react";
import Block from "UI/App/Components/Block/Block";
import Boolean from "UI/App/Components/Form/Boolean";
import Button from "UI/App/Components/Button/Button";
import Grid from "UI/App/Components/Grid/Grid";
import Icon from "UI/App/Components/Icon/Icon";
import MaterialDetail from "./MaterialDetail";
import Table from "UI/App/Components/Table/Table";
import {FetchContext} from "App/Strapi/FetchContext";
import {Link, useParams} from "react-router-dom";
import {formatDate, formatDateWithoutTime, formatSmallCurrency} from "App/Util/format";
import useWindowDimensions from "App/Util/useWindowDimensions";
import {openPopup} from "UI/App/Components/Popup/Popup";
import {toast} from "react-toastify";
import './Material.scss';
import MaterialStockHistory from "UI/App/Partials/Content/Calculations/Catalog/MaterialStockHistory/MaterialStockHistory";
import WriteOffMaterialPopup from "UI/App/Components/Popup/Popups/MaterialPopup/WriteOffMaterialPopup";
import RegisterMaterialPopup from "UI/App/Components/Popup/Popups/MaterialPopup/RegisterMaterialPopup";
import Container from "UI/App/Components/Container/Container";
import ConfirmationPopup from "UI/App/Components/Popup/Popups/Util/ConfirmationPopup";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";

export default function Material() {
    // create a new fetch context for fetching the material
    const {authAxios} = useContext(FetchContext);

    // get the material id from the url params
    const params = useParams();

    // create a state for the material data
    const [material, setMaterial] = useState({});

    const [wareHouseData, setWareHouseData] = useState([]);
    const [chosenMaterialStock, setChosenMaterialStock] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [historyPage, setHistoryPage] = useState(1);
    const [historyData, setHistoryData] = useState([]);
    const [popupData, setPopupData] = useState();
    const [targetUnit, setTargetUnit] = useState();
    const { width } = useWindowDimensions();

    const loadHistory = useCallback(() => {
        setIsLoading(true);
        authAxios.get(`/logistics/materials/${params.materialId}/stock/history?page=1`)
            .then(({data}) => {
                setHistoryData(data);
                if (data.length > 0) {
                    setHistoryPage(2);
                } else {
                    setHistoryPage(1);
                }
            }).then(() => setIsLoading(false))
    }, [authAxios, params.materialId])

    const getNextHistoryRecords = useCallback(() => {
        setIsLoading(true);

        authAxios.get(`/logistics/materials/${params.materialId}/stock/history?page=${historyPage}`)
            .then(({data}) => {
                setHistoryData(prev => {
                    return [
                        ...prev,
                        ...data
                    ];
                })

                if (data.length > 0) {
                    setHistoryPage(historyPage + 1);
                }
            }).then(() => {
                setIsLoading(false)
            })
    }, [authAxios, historyPage, params.materialId])

    // fetch the material based on the id
    useEffect(() => {
        loadData();
    }, [authAxios, params.materialId]);

    const loadData = () => {
        authAxios.get(`/logistics/materials/${params.materialId}?populate=stock.warehouse`)
            .then(async ({data}) => {
                // set the material
                setMaterial(data);
                const materialUnitMutator = new MaterialUnitMutator();
                const targetUnit = await materialUnitMutator.obtainUnit(data.id);

                let tableData = [];
                for (const stockItem of data.stock) {

                    // Skip empty rows
                    // if (stockItem.total <= 1) continue
                    const convertedTotalQuantity = await materialUnitMutator.quantityMerchant(stockItem.total, data, true);
                    const convertedAvailableQuantity = await materialUnitMutator.quantityMerchant(stockItem.available, data, true);
                    const convertedReservedQuantity = await materialUnitMutator.quantityMerchant(stockItem.reserved, data, true);
                    const convertedWrittenOffQuantity = await materialUnitMutator.quantityMerchant(stockItem.writtenOff, data, true);


                    tableData.push({
                        data: [
                            stockItem.warehouse.name,
                            <span className={'btn btn--transparent'} style={{ color: 'black' }} data-title={`${convertedTotalQuantity?.toFixed(2)} ${targetUnit || data.unit}`}>{Number(stockItem.total ?? 0).toFixed(2)} {stockItem.unit}</span>,
                            <span className={'btn btn--transparent'} style={{ color: 'black' }} data-title={`${convertedAvailableQuantity?.toFixed(2)} ${targetUnit || data.unit}`}>{Number(stockItem.available ?? 0).toFixed(2)} {stockItem.unit}</span>,
                            <span className={'btn btn--transparent'} style={{ color: 'black' }} data-title={`${convertedReservedQuantity?.toFixed(2)} ${targetUnit || data.unit}`}>{Number(stockItem.reserved ?? 0).toFixed(2)} {stockItem.unit}</span>,
                            <span className={'btn btn--transparent'} style={{ color: 'black' }} data-title={`${convertedWrittenOffQuantity?.toFixed(2)} ${targetUnit || data.unit}`}>{Number(stockItem.writtenOff ?? 0).toFixed(2)} {stockItem.unit}</span>,
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button data-title={'Verwijderen'} className='btn--transparent btn--small' onClick={() => {
                                    setPopupData(stockItem)
                                    openPopup("confirmationPopup")
                                }} ><Icon name='trash-can' /></button>
                                <button data-title={'Afboeken'} className='btn--transparent btn--small' onClick={() => {
                                    setChosenMaterialStock(stockItem)
                                    openPopup('writeOffMaterial')
                                }}><Icon name='arrow-down' style={{ fill: 'red' }} /></button>
                            </div>
                        ]
                    })
                }
                setWareHouseData(tableData)

                loadHistory();
            })
            .catch((exception) => {
                toast.error("Kon materiaal niet ophalen");

                console.error(exception);
            });
    }

    function setArchived(archived) {
        authAxios.put(`/logistics/materials/${params.materialId}`, {
            data: {
                active: archived
            }
        }).then(({data}) => {
            if(data.data.active){
                toast.success(`Materiaal geactiveerd.`);
            }else{
                toast.warn(`Materiaal gedeactiveerd.`);
            }
        }).catch((exception) => {
            toast.error(`${archived ? 'Activeren' : 'Deactiveren'} mislukt! ${exception?.response?.status ? `(${exception.response.status})` : ''}`);

            console.error(exception);
        });
    }

    function deleteMaterial(stockId) {
        authAxios.delete(`/logistics/materials/stock/${stockId}/remove`).then(({ data }) => {
           toast.success(`Materiaal Verwijderd.`);
            loadData();
        }).catch((exception) => {
            toast.error(`Materiaal verwijderen mislukt! ${exception?.response?.status ? `(${exception.response.status})` : ''}`);

            console.error(exception);
        });
    }

    const addStorageCallback = () => {
        loadData();
        loadHistory();
    }

    return (
        <Grid
            columns='1'
        >
            <Grid
                columns='2'
                customColTemplate={{
                    1400: '1fr',
                    1500: '1fr 500px',
                    default: '1fr 600px',
                }}
            >
                <Block
                    name='material__details' title={
                    <>
                        <Link className={'btn btn--transparent'} to={`/calculations/catalog`}><Icon name={'arrow-left'}/></Link>
                        {material?.quotationNameNL ?? material?.quotationNameEN}
                    </>}
                    headerRightSideChildren={<>
                        <span>
                            {(width >= 1400 && width < 1445) ?
                                <>
                                    <b>bijgewerkt op:</b>
                                    <span style={{ marginLeft: '10px' }}>{formatDateWithoutTime(material?.updatedAt)}</span>
                                </>
                                :
                                <>
                                    <b>Laatst bijgewerkt:</b>
                                    <span style={{ marginLeft: '10px' }}>{formatDate(material?.updatedAt)}</span>
                                </>

                            }
                        </span>
                        <Button to={'update'} className='btn btn--black btn--round'><Icon name={'pencil'}/></Button>
                    </>}
                >
                    <Grid
                        gap='0px var(--gap-l)'
                        columns='2'
                        customColTemplate={{
                            750: '1fr',
                            1400: '1fr 1fr',
                            1500: '1fr',
                            default: '1fr 1fr'
                        }}
                    >
                        <div className='detail__column'>
                            {(material?.internalSKU || material?.supplierSKU) && <MaterialDetail key='SKU' title="SKU's" content={[
                                material?.internalSKU && <><b>Artikelnummer:</b> {material.internalSKU}</>,
                                material?.supplierSKU && <><b>Artikelnummer leverancier:</b> {material.supplierSKU}</>,
                            ]}/>}

                            <MaterialDetail display={material.quotationNameEN || material.quotationNameNL} key='quotationName' title='Materiaal' content={[
                                material?.quotationNameNL && <><b>Nederlands:</b> {material.quotationNameNL}</>,
                                material?.quotationNameEN && <><b>Engels:</b> {material.quotationNameEN}</>
                            ]}/>

                            {material?.category && <MaterialDetail key='category' title='Categorie' content={[
                                material.category
                            ]}/>}

                            <MaterialDetail display={(material?.purchasePrice || material?.regularPrice || material?.salePrice)} key='price' title='Kosten' content={[
                                material?.purchasePrice && <><b>Inkoopprijs:</b> {formatSmallCurrency(material.purchasePrice, 6)}</>,
                                material?.regularPrice && <><b>Actuele inkoop:</b> {formatSmallCurrency(material.regularPrice, 6)}</>,
                                material?.salePrice && <><b>Verkoopprijs:</b> {formatSmallCurrency(material.salePrice, 6)}</>,

                            ]} />

                            {(material?.unitsPerOrder || material?.countPerUnit) && <MaterialDetail key='counts' title='Aantallen' content={[
                                material?.unitsPerOrder && <><b>Min. aantal per bestelling:</b> {material.unitsPerOrder} {material?.unitsPerOrderName ?? ''}</>,
                                material?.countPerUnit && <><b>Aantal per eenheid:</b> {material.countPerUnit} {material?.unit ?? ''}</>,
                                material?.thresholdAmount && <><b>Drempel waarde:</b> {material.thresholdAmount} {material?.unit ?? ''}</>,

                            ]} />}
                        </div>
                        <div className='detail__column'>
                            {(material?.lengthInMeters || material?.widthInMeters || material?.heightInMeters || material?.thicknessInMM) && <MaterialDetail key='sizes' title='Afmetingen' content={[
                                material?.lengthInMeters && <><b>Lengte:</b> {material.lengthInMeters} meter</>,
                                material?.widthInMeters && <><b>Breedte:</b> {material.widthInMeters} meter</>,
                                material?.heightInMeters && <><b>Hoogte:</b> {material.heightInMeters} meter</>,
                                material?.thicknessInMM && <><b>Dikte:</b> {material.thicknessInMM} millimeter</>,
                            ]}/>}

                            <MaterialDetail display={material?.weightInGram} key='weight' title='Gewicht' content={[
                                `${material.weightInGram} gram`,
                            ]}/>

                            {material?.runningDirection && <MaterialDetail key='runningDirection' title='Looprichting' content={[
                                material.runningDirection,
                            ]}/>}

                            {material?.coated !== null && <MaterialDetail key='coated' title='Coated' content={[
                                (material?.coated ?? null) !== null ? (material?.coated ? 'Ja' : 'Nee') : '',
                            ]}/>}

                            {material.active !== undefined &&
                                <MaterialDetail key='active' title='Status' content={[
                                    <Boolean
                                        displayFalse={'Gearchiveerd'}
                                        displayTrue={'Actief'}
                                        value={material?.active}
                                        nullable={false}
                                        field={{
                                            id: 'active',
                                            name: 'active'
                                        }}
                                        falseIsRed={true}
                                        setValue={(name, value) => {
                                            setArchived(value)
                                        }}
                                    />
                                ]}/>}
                        </div>
                    </Grid>
                </Block>

                {/* Material stock history */}
                <MaterialStockHistory
                    isLoading={isLoading}
                    historyData={historyData}
                    getNextHistoryRecords={getNextHistoryRecords}
                />
            </Grid>

            <Container style={{
                borderRadius: '5px',
                overflow: 'hidden',
                paddingBottom: '10px'
            }}>
                <Table
                    headers={[
                        'Magazijn',
                        'Aanwezig',
                        'Beschikbaar',
                        'Gereserveerd',
                        'Afgeboekt',
                        {
                            attributes: {
                                style: {
                                    textAlign: 'center'
                                }
                            },
                            value: <button data-title='Inboeken' className='btn btn--black' onClick={() => openPopup('registerMaterial')}><Icon name='plus'/></button>
                        }
                    ]}
                    structure={{
                        1600: ['200px', '25%', '25%', '25%', '25%', '70px'],
                        default: ['40%', '15%', '15%', '15%', '15%', '70px']
                    }}
                    data={wareHouseData}
                    hover
                    border='row'
                    className='table--padding-m'
                    columnOverflowExceptions={[1, 2, 3, 4, 5]}
                    style={{
                        borderRadius: '5px',
                        overflow: 'visible'
                    }}
                    wrapperStyle={{
                        overflow: 'visible'

                    }}
                />
            </Container>

            <WriteOffMaterialPopup
                key={wareHouseData.length}
                defaultMaterialId={material.id}
                defaultUnit={material.unit}
                defaultQuantity={0}
                defaultWarehouse={chosenMaterialStock?.warehouse?.id}
                onClose={addStorageCallback}
                materialLocked={true}
            />

            <RegisterMaterialPopup
                defaultMaterialId={material.id}
                defaultUnit={material.unit}
                defaultQuantity={0}
                defaultWarehouse={chosenMaterialStock?.warehouse?.id}
                onClose={addStorageCallback}
                materialLocked={true}
            />

            <ConfirmationPopup
                title={"Verwijderen"}
                subject={"Materiaal verwijderen"}
                description={<>Materiaal verijderen uit het magazijn <b>{popupData?.warehouse?.name}</b>?</>}
                confirmButtonProps={{ value: popupData?.id }}
                functionOnConfirm={(e) => deleteMaterial(e.target.value)}
            />
        </Grid>
    )
}
