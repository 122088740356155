import {usePagination} from "UI/App/Components/Pagination/Pagination";
import Table from "../../../../Components/Table/Table";
import {useContext, useEffect, useState} from "react";
import Button from "UI/App/Components/Button/Button";
import {FetchContext} from "App/Strapi/FetchContext";
import {useNavigate} from "react-router-dom";
import Icon from "UI/App/Components/Icon/Icon";
import capitalize from "lodash/capitalize";
import {toast} from "react-toastify";
import {stringify} from "qs";
import Popup, {openPopup,closePopup} from "../../../../Components/Popup/Popup";
import CreateOrder from "./CreateOrder";
import {formatDateWithoutTime} from "../../../../../../App/Util/format";
import HasRole from "UI/App/Components/Auth/HasRole";
import IF from "UI/App/Components/Conditional/IF";
import User from "App/Strapi/User";
import CopyOrder from "UI/App/Partials/Content/CRM/Orders/CopyOrder";

export default function Orders() {
    const [tableData, setTableData] = useState([]);
    const [orderIdToCopy, setOrderIdToCopy] = useState(null);
    const {authAxios} = useContext(FetchContext);
    const navigate = useNavigate();

    const {
        paging,
        filtering,
        currentPage,
        resultsPerPage,
        setTotalPages,
        setTotalResults,
        filterQuery,
        paginationStateLoaded,
    } = usePagination({
        storageKey: 'order',
        searchSettings: {
            enabled: true,
            strapiFields: [
                'number',
                '[quoteConfirmation][quote][company][name][description]',
                '[company][name]',
                'description'
            ]
        },
        htmlElements: [
            <span className='pagination__filter addItem' key='link--add'>
                <span style={{cursor: "pointer"}} onClick={(e) => {
                    openPopup("addOrder")
                }} className='btn btn--add btn--icon-right'>Toevoegen <Icon name='plus'/></span>
            </span>,
        ],
        filters: [
            {
                name: "status",
                type: "select",
                options: [
                    {value: "*", name: "Alle"},
                    {value: "Binnengekomen", name: "Binnengekomen"},
                    {value: "Factureren", name: "Factureren"},
                    {value: "Productie", name: "Productie"},
                    {value: "Derden", name: "Derden"},
                    {value: "Verzending", name: "Verzending"},
                    {value: "Klacht", name: "Klacht"},
                    {value: "Afgerond", name: "Afgerond"},
                    {value: "Afgeboekt", name: "Afgeboekt"},
                    {value: "Reservering", name: "Reservering"},
                    {value: "afgekeurd door pretore", name: "Afgekeurd door Pretore"},
                ],
            }, {
                name: 'owner',
                type: 'select',
                options: [
                    { name: "Alle bedrijven", value: '*' },
                    { name: "HJMG", value: "hjmg" },
                    { name: "Pretore", value: "pretore" },
                ],
                callbackFunction: callbackFilter
            }
        ],
    });

    useEffect(() => {
        let roleSpecificFilter = {}
        if (User.hasRole(['Productie medewerker'])) {
            roleSpecificFilter = {
                status: {
                    $in: ["binnengekomen", "productie", "verzending", "derden", "klacht"]
                }
            }
        }

        const params = stringify({
            sort: {
                id: 'desc'
            },
            pagination: {
                page: currentPage,
                pageSize: resultsPerPage
            },
            populate: {
                company: {
                    fields: ["name"]
                },
                contactPerson: true,
                calculation: {
                    fields: [
                        'customerReference',
                        'owner'
                    ],
                },
                quoteConfirmation: {
                    fields: [
                        'number'
                    ],
                    populate: {
                        quote: {
                            populate: {
                                company: {
                                    fields: [
                                        'name'
                                    ]
                                }
                            },
                            fields: [
                                'number'
                            ]
                        },
                    }
                },
                project: {
                    fields: ['id'],
                    populate: [
                        'machinePlanItems'
                    ]
                },
                planning: {
                    fields: ['id'],
                }
            },
            fields: [
                'number',
                'status',
                'description',
                'deliveryDate'
            ],
            filters: {
                $and: [
                    {
                        number: {
                            $nei: 'onderhoud'
                        }
                    },
                    roleSpecificFilter
                ]
            }
        })

        // get the companies using the built params
        authAxios.get(`/orders?${params}&${filterQuery}`)
            .then(({data}) => {
                const tableData = [];
                const statusses = [
                    'Binnengekomen',
                    'Factureren',
                    'Productie',
                    'Derden',
                    'Verzending',
                    'Klacht',
                    'Afgerond',
                    'Afgeboekt',
                    'Reservering',
                    'Afgekeurd door Pretore'
                ]

                // loop through all the records
                for (const order of data.data) {

                    // Skip order if order has no calculation
                    if (order?.calculation === null) continue;

                    // console.log(order);
                    let options = [];
                    for (const status of statusses) {
                        options.push(<option key={status}>{capitalize(status)}</option>)
                    }

                    // Get calculation from confirmation or order
                    let calculation = null;
                    if (order?.quoteConfirmation !== null && order?.quoteConfirmation !== undefined) {
                        calculation = order.quoteConfirmation.calculation;
                    } else if (order?.calculation !== null && order?.calculation !== undefined) {
                        calculation = order.calculation;
                    }

                    let reference = 'Leeg';
                    let calculationTemplate = 'Leeg';
                    let description = 'Leeg';
                    if (order.description !== null) {
                        description = order.description
                    }

                    if (calculation !== undefined ? (calculation?.customerReference !== null && calculation?.customerReference !== undefined) : false) {
                        reference = calculation.customerReference;
                    }

                    tableData.push({
                        attributes: {
                            onClick: () => {
                                navigate(`/orders/${order.id}`)
                            },
                            id: order.id
                        },
                        data: [
                            <div className='owner-indicator'>
                                <IF condition={typeof order?.calculation?.owner === 'string'}>
                                    <span className={`circle circle-${order?.calculation?.owner?.toLowerCase()}`}></span>
                                </IF>
                            </div>,
                            order.number,
                            order?.company?.name ?? '',
                            description,
                            order.deliveryDate !== null ? formatDateWithoutTime(order.deliveryDate) : '',
                            <>
                                <HasRole roles={['admin', 'management', 'productie manager']}>
                                    <select defaultValue={capitalize(order?.status) ?? undefined} onClick={(e) => {
                                        e.stopPropagation()
                                        }} onChange={(e) => {
                                            updateStatus(e, order.id)
                                        }}>
                                    {options}
                                    </select>
                                </HasRole>
                                <HasRole roles={['productie medewerker']}>
                                    {order?.status}
                                </HasRole>
                            </>,
                            <div className={'table__action-buttons'}>
                                <Button type='button' data-title={'Kopiëren'} className={'btn btn--transparent'} onClick={(e) => {
                                    e.stopPropagation()
                                    setOrderIdToCopy(order.id)
                                    openPopup("copyOrder")
                                }}><Icon name={'copy'}/></Button>
                                <Button data-title={'Bekijken'} className={'btn btn--transparent'} to={`/orders/${order.id}`} onClick={(e) => e.stopPropagation()}><Icon name={'eye'}/></Button>
                                <IF condition={order?.calculation.owner !== null && order?.calculation.owner.toLowerCase() === 'pretore'}>
                                    <HasRole roles={['admin', 'management', 'productie manager']}>
                                        <Button data-title={'Planning'} className={'btn btn--transparent'} onClick={(e) => e.stopPropagation()} to={(!order.planning) ? `/planning/orders/${order.id}/create` : `/planning/orders/${order.id}`}><Icon name={'calendar'}/></Button>
                                    </HasRole>
                                </IF>
                            </div>
                        ]
                    })
                }
                setTableData(tableData);

                // Set pagination
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
            }).catch((exception) => {
            toast.error(`Er is iets fout gegaan ${exception?.response?.status ? `(${exception.response?.status})` : ''}`);
            console.error(exception);
        })
    }, [currentPage, filterQuery, resultsPerPage, paginationStateLoaded])

    const updateStatus = (e, orderId) => {

        authAxios.put(`/orders/${orderId}`, {
            "data": {
                "status": e.target.value.toLowerCase()
            }
        }).then(({data}) => {
            toast.success(`Order: ${data.data.number} status omgezet naar ${e.target.value}`);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van order.`);
        });
    }
    function callbackFilter(e) {

        // gaurd against no value or default value
        if (e?.currentValue === undefined || e?.currentValue === "*") return;
        // create the custom filter object
        let returnObject = {
            calculation: {
                owner: e.currentValue
            }
        }
        // return the custom object
        return returnObject;
    }

    function closeOrderCopy(){
        closePopup('copyOrder')
    }

    return (
        <div>
            <Popup
                overflow={true}
                popupID='copyOrder'
                title={'Order kopiëren'}
            >
                <CopyOrder
                    orderId={orderIdToCopy}
                    closePopup={() => closeOrderCopy()}
                />
            </Popup>
            <Popup
                overflow={true}
                popupID='addOrder'
                title={'Order toevoegen'}
            >
                <CreateOrder/>
            </Popup>
            {filtering}
            <Table
                headers={['', 'Nummer', 'Klant', 'Omschrijving', 'Lever datum', 'Status', '']}
                structure={{
                    500: ['40px', '100%', 0, 0, 0, 0, '115px'],
                    750: ['40px', '150px', '100%', 0, 0, 0, '115px'],
                    800: ['40px', '150px', '100%', 0, 0, 0, '115px'],
                    1000: ['40px', '150px', '100%', 0, 0, '200px', '115px'],
                    1350: ['40px', '150px', '250px', '100%', 0, '200px', '115px'],
                    default: ['40px', '150px', '250px', '100%', '200px', '200px', '115px'],
                }}
                data={tableData}
                hover
                border='row'
                className={'table--padding-m'}
                columnOverflowExceptions={[6]}
                columnTranslationExceptions={[1, 2]}
            />
            {paging}
        </div>
    )
}
