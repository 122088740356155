import {useContext, useState} from "react";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";

export default function CreateWareHouse({closePopup = () => {}}) {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const { authAxios } = useContext(FetchContext);

    const createWarehouse = () => {
        console.log(name,location);

        let json = {
            data: {
                name: name,
                location: location,
            }
        }

        authAxios.post(`/logistics/warehouses`, json).then(({ data }) => {
            toast.success(`Magazijn aangemaakt!`);
            closePopup();
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het opslaan van het magazijn!`);
        });
    }

    return (
        <>
            <div className='input-group'>
                <label htmlFor='draggable-popup-assign'>
                    Naam
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}

                />
                <label htmlFor='draggable-popup-assign'>
                    Locatie
                </label>
                <input
                    type="text"
                    value={location}
                    onChange={(e) => {
                        setLocation(e.target.value)
                    }}
                />
                <button onClick={createWarehouse} disabled={name === '' || location === ''}>
                    Opslaan
                </button>
            </div>
        </>
    );
}
