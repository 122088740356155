import {AuthProvider} from 'App/Strapi/AuthProvider';
import {ConfigProvider} from 'App/Strapi/ConfigContext';
import {FetchProvider} from 'App/Strapi/FetchContext';
import {StrapiAuthenticator} from 'App/Strapi/StrapiAuthenticator';
import TerminalAuthenticator from "App/Strapi/TerminalAuthenticator";
import {createBrowserRouter, createRoutesFromElements, Outlet, Route} from 'react-router-dom';
import AppShell from 'UI/App/AppShell';
import Account from 'UI/App/Partials/Account';
import NotFound from 'UI/App/Partials/App/NotFound';
import RoutingErrorHandler from 'UI/App/Partials/App/RoutingErrorHandler';
import Login from 'UI/App/Partials/Auth/login';
import Logout from 'UI/App/Partials/Auth/logout';
import Config from 'UI/App/Partials/Content/Admin/Config/Config';
import LogDetail from 'UI/App/Partials/Content/Admin/Log/LogDetail';
import Logs from 'UI/App/Partials/Content/Admin/Log/Logs';
import Roles from 'UI/App/Partials/Content/Admin/Roles/Roles';
import User from 'UI/App/Partials/Content/Admin/Users/EditUser';
import Users from 'UI/App/Partials/Content/Admin/Users/Users';
import CalculationOwnerSwitch from "UI/App/Partials/Content/Calculations/CalculationOwnerSwitch";
import CalculationOverView from 'UI/App/Partials/Content/Calculations/Calculations/overview';
import EditPrice from 'UI/App/Partials/Content/Calculations/Catalog/EditPrice';
import Catalog from 'UI/App/Partials/Content/Calculations/Catalog/Index';
import Material from 'UI/App/Partials/Content/Calculations/Catalog/Material';
import MaterialCreateUpdate from 'UI/App/Partials/Content/Calculations/Catalog/MaterialCreateUpdate';
import CreateHJMGCalculation from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";
import CalculationSettings from 'UI/App/Partials/Content/Calculations/Settings/Index';
import MachineCreateUpdate from 'UI/App/Partials/Content/Calculations/Settings/Machine/MachineCreateUpdate';
import CalculationTabsOverview from 'UI/App/Partials/Content/Calculations/Tabs/CalculationTabsOverview';
import CreateEditCalculationTabs from 'UI/App/Partials/Content/Calculations/Tabs/CreateEditCalculationTabs';
import ViewSwitcher from "UI/App/Partials/Content/Calculations/ViewSwitcher";
import CreateFlow from 'UI/App/Partials/Content/Calculations/Widgets/CreateFlow';
import Company from 'UI/App/Partials/Content/CRM/Companies/Company/Company';
import ContactPerson from 'UI/App/Partials/Content/CRM/Companies/Company/PersonDetails/ContactPerson';
import CreateContactPerson from 'UI/App/Partials/Content/CRM/Companies/Company/PersonDetails/CreateContactPerson';
import EditContactPerson from 'UI/App/Partials/Content/CRM/Companies/Company/PersonDetails/EditContactPerson';
import Branch from 'UI/App/Partials/Content/CRM/Companies/Company/Vestigingen/Branch';
import CreateBranch from 'UI/App/Partials/Content/CRM/Companies/Company/Vestigingen/CreateBranch';
import EditBranch from 'UI/App/Partials/Content/CRM/Companies/Company/Vestigingen/EditBranch';
import CreateCompany from 'UI/App/Partials/Content/CRM/Companies/CreateCompany';
import EditCompany from 'UI/App/Partials/Content/CRM/Companies/EditCompany';
import CRM from 'UI/App/Partials/Content/CRM/CRM';
import ManagersIndex from 'UI/App/Partials/Content/CRM/Managers';
import CreateManager from 'UI/App/Partials/Content/CRM/Managers/CreateManager';
import OrderPdf from 'UI/App/Partials/Content/CRM/Orders/OrderPdf';
import CreateOrderPackingSlip from 'UI/App/Partials/Content/CRM/Orders/PackingSlips/CreateOrderPackingSlip';
import OrdersPackingSlips from 'UI/App/Partials/Content/CRM/Orders/PackingSlips/OrdersPackingSlips';
import CreateJobTicket from "UI/App/Partials/Content/CRM/Workflow/CreateJobTicket";
import ViewJobLogging from 'UI/App/Partials/Content/CRM/Workflow/JobLoggingOverview';
import Dashboard from 'UI/App/Partials/Content/Dashboard/Dashboard';
import GeneralPlanning from 'UI/App/Partials/Content/Planning/GeneralPlanning';
import Planning from 'UI/App/Partials/Content/Planning/Planning';
import PlanningCreator from 'UI/App/Partials/Content/Planning/PlanningCreator';
import CreatePurchaseQuote from 'UI/App/Partials/Content/Purchases/Quotes/CreatePurchaseQuote';
import PurchaseQuote from 'UI/App/Partials/Content/Purchases/Quotes/PurchaseQuote';
import CreatePurchaseSlip from 'UI/App/Partials/Content/Purchases/Slips/CreatePurchaseSlip';
import PurchaseSlip from 'UI/App/Partials/Content/Purchases/Slips/PurchaseSlip';
import CreateSticker from 'UI/App/Partials/Content/Sticker/CreateSticker';
import TimeAccounting from 'UI/App/Partials/Content/TimeAccounting/TimeAccounting';
import CreateWareHouse from "UI/App/Partials/Content/Warehouse/CreateWareHouse";
import Warehouse from "UI/App/Partials/Content/Warehouse/Warehouse";
import WareHouses from "UI/App/Partials/Content/Warehouse/WareHouses";
import CreateCalculation from './UI/App/Partials/Content/Calculations/Calculations/create';
import Checklists from './UI/App/Partials/Content/Calculations/Checklists';
import CreateUpdateChecklist from './UI/App/Partials/Content/Calculations/Checklists/CreateUpdateChecklist';
import MachineView from './UI/App/Partials/Content/Calculations/Settings/Machine/view';
import EditCalculationTemplate from './UI/App/Partials/Content/Calculations/Template/EditCalculationTemplate';
import TemplateIndex from './UI/App/Partials/Content/Calculations/Template/templateIndex';
import EditFlow from './UI/App/Partials/Content/Calculations/Widgets/EditFlow';
import WidgetCollection from './UI/App/Partials/Content/Calculations/Widgets/WidgetCollection';
import SetCompanyManagers from './UI/App/Partials/Content/CRM/Companies/Company/PersonDetails/SetCompanyManagers';
import CreateOrderConfirmation from './UI/App/Partials/Content/CRM/OrderConfirmations/CreateOrderConfirmation';
import OrderConfirmation from './UI/App/Partials/Content/CRM/OrderConfirmations/OrderConfirmation';
import OrderConfirmations from './UI/App/Partials/Content/CRM/OrderConfirmations/OrderConfirmations';
import CreateOrder from './UI/App/Partials/Content/CRM/Orders/CreateOrder';
import Orders from './UI/App/Partials/Content/CRM/Orders/Orders';
import ViewOrder from './UI/App/Partials/Content/CRM/Orders/ViewOrder';
import CreateUpdateQuote from './UI/App/Partials/Content/CRM/Quotes/CreateUpdateQuote';
import Quote from './UI/App/Partials/Content/CRM/Quotes/Quote';
import Quotes from './UI/App/Partials/Content/CRM/Quotes/Quotes';
import Project from './UI/App/Partials/Content/Projects/Project';
import Projects from './UI/App/Partials/Content/Projects/Projects';
import PurchaseQuotesOverview from './UI/App/Partials/Content/Purchases/Quotes/PurchaseQuotesOverview';
import PurchaseSlipsOverview from './UI/App/Partials/Content/Purchases/Slips/PurchaseSlipsOverview';
import StocksOverview from './UI/App/Partials/Content/Purchases/Stocks/StocksOverview';
import MachinePlanningOverview from "UI/App/Partials/Content/Planning/MachinePlanningOverview";
import PersonalPlanning from "UI/App/Partials/Content/Planning/PersonalPlanning";
import Terminal from 'UI/App/Partials/Content/Admin/Terminals/CreateUpdateTerminal';
import Terminals from 'UI/App/Partials/Content/Admin/Terminals/Terminals';
import ViewTimers from 'UI/App/Partials/Content/CRM/Orders/OrderTimer/ViewTimers';
import ViewMaterials from 'UI/App/Partials/Content/CRM/Orders/OrderMaterial/ViewMaterials';
import XmlEditJobTicket from 'UI/App/Partials/Content/CRM/Workflow/XmlEditJobTicket';

export default createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<ProviderProvider />} errorElement={<RoutingErrorHandler />}>
            {/* public routes */}
            <Route path='login' element={<Login />} />
            <Route path='authenticate' element={<StrapiAuthenticator />} />
            <Route path='logout' element={<Logout />} />
            <Route path='login/terminal' element={<TerminalAuthenticator />} />

            {/* Authenticated routes */}
            <Route path='' element={<AppShell />}>
                {/* Dashboard route */}
                <Route index element={<Dashboard />} />
                <Route path={'dashboard'} element={<Dashboard />} />

                {/* Quote routes */}
                <Route path='quotes'>
                    {/* Quote overview */}
                    <Route index element={<Quotes />} />

                    <Route path='create' element={<CreateUpdateQuote />}>
                        <Route path=':calculationId' element={<CreateUpdateQuote />} />
                    </Route>

                    {/* Quote page */}
                    <Route path={':quoteId'} element={<Quote />} />

                    {/* Copy quote page */}
                    <Route path=':quoteId/copy' element={<CreateUpdateQuote copy={true} />} />

                    {/* Copy quote page */}
                    <Route path=':quoteId/revision' element={<CreateUpdateQuote revision={true} />} />

                    {/* Copy edit page */}
                    <Route path={':quoteId/edit'} element={<CreateUpdateQuote edit={true} />} />
                </Route>

                {/* Project routes */}
                <Route path='projects'>
                    <Route index element={<Projects />} />
                    <Route path=':projectId'>
                        <Route index element={<Project />} />
                    </Route>
                </Route>

                {/* Order logging */}
                <Route path='joblogging'>
                    <Route index element={<NotFound />} />
                    <Route path=':jobId' element={<ViewJobLogging key='view' />} />
                </Route>

                {/* Order routes */}
                <Route path='orders'>
                    <Route index element={<Orders />} />

                    <Route path='timers'>
                        <Route index element={<ViewTimers />} />
                        <Route path=':orderId' element={<ViewTimers />} />
                        <Route path='user/:userId' element={<ViewTimers />} />
                    </Route>

                    <Route path='materials'>
                        <Route index element={<ViewMaterials />} />
                        <Route path=':orderId' element={<ViewMaterials />} />
                    </Route>

                    <Route path=':orderId'>
                        <Route index element={<ViewOrder />} />
                        <Route path='pdf' element={<OrderPdf />} />

                        <Route path='packing-slips/create' element={<CreateOrderPackingSlip />} />
                        <Route path='packing-slips/:slipId' element={<CreateOrderPackingSlip />} />

                        <Route path='job-ticket'>
                            <Route path='create' element={<CreateJobTicket key='create' />} />
                            <Route path=':ticketId' element={<XmlEditJobTicket key='edit' />} />
                            <Route path=':jobId/logs' element={<ViewJobLogging key='view' />} />
                        </Route>


                        <Route path='sticker'>
                            <Route path='create' element={<CreateSticker />} />
                        </Route>
                    </Route>

                    <Route path='confirmations'>
                        <Route index element={<OrderConfirmations />} />

                        <Route path=':quoteId' element={<CreateOrderConfirmation />} />

                        <Route path=':confirmationId/copy' element={<CreateOrderConfirmation copy={true} />} />
                        <Route path=':confirmationId/revision' element={<CreateOrderConfirmation revision={true} />} />
                    </Route>

                    <Route path='confirmation/:confirmationId'>
                        <Route index element={<OrderConfirmation />} />
                    </Route>

                    <Route path='create'>
                        {/* Quote page */}
                        <Route index element={<CreateOrder />} />
                    </Route>

                    <Route path='packing-slips'>
                        <Route index element={<OrdersPackingSlips />} />
                    </Route>
                </Route>

                {/* CRM routes */}
                <Route path='crm'>
                    {/* CRM */}
                    <Route index element={<CRM />} />

                    {/* Archive */}
                    <Route path='archive' element={<CRM archived={true} />} />

                    {/* Any applied filter */}
                    <Route path=':type' element={<CRM />} />

                    {/* Companies */}
                    <Route path='companies'>
                        {/* No page found here */}
                        <Route index element={<NotFound />} />

                        {/* Company specific */}
                        <Route path=':companyId'>
                            {/* Company page */}
                            <Route index element={<Company />} />

                            {/* Update */}
                            <Route path='update' element={<EditCompany />} />

                            {/* Responsible persons */}
                            <Route path='responsible-people' element={<SetCompanyManagers />} />

                            {/* Branches */}
                            <Route path='branch'>
                                {/* No page found here */}
                                <Route index element={<NotFound />} />

                                <Route path=':branchId'>
                                    {/* View branch */}
                                    <Route index element={<Branch />} />

                                    {/* Edit branch */}
                                    <Route path='update' element={<EditBranch />} />
                                </Route>

                                {/* create branch */}
                                <Route path='create' element={<CreateBranch />} />
                            </Route>

                            {/* Contactpersons */}
                            <Route path='contactpersons'>
                                {/* No page found here */}
                                <Route index element={<NotFound />} />

                                {/* ContactPerson */}
                                <Route path=':contactPersonId'>
                                    {/* View person */}
                                    <Route index element={<ContactPerson />} />

                                    {/* Edit person */}
                                    <Route path='update' element={<EditContactPerson />} />
                                </Route>

                                {/* Create new person */}
                                <Route path='create' element={<CreateContactPerson />} />
                            </Route>
                        </Route>

                        {/* Create company */}
                        <Route path='create'>
                            <Route index element={<CreateCompany />} />
                            {/* Any applied filter */}
                            <Route path=':type' element={<CreateCompany />} />
                        </Route>
                    </Route>

                    {/* Suppliers */}
                    <Route path='suppliers'>
                        {/* No page found here */}
                        <Route index element={<CRM />} />

                        {/* Supplier specific */}
                        <Route path=':companyId'>
                            {/* Supplier page */}
                            <Route index element={<Company />} />

                            {/* Update */}
                            <Route path='update' element={<EditCompany />} />

                            {/* Branches */}
                            <Route path='branch'>
                                {/* No page found here */}
                                <Route index element={<NotFound />} />

                                <Route path=':branchId'>
                                    {/* View branch */}
                                    <Route index element={<Branch />} />

                                    {/* Edit branch */}
                                    <Route path='update' element={<EditBranch />} />
                                </Route>

                                {/* create branch */}
                                <Route path='create' element={<CreateBranch />} />
                            </Route>

                            {/* Contactpeople */}
                            <Route path='contactpersons'>
                                {/* No page found here */}
                                <Route index element={<NotFound />} />

                                {/* ContactPerson */}
                                <Route path=':contactPersonId'>
                                    {/* View ContactPerson */}
                                    <Route index element={<ContactPerson />} />

                                    {/* Edit person */}
                                    <Route path='update' element={<EditContactPerson />} />
                                </Route>

                                {/* Create new person */}
                                <Route path='create' element={<CreateContactPerson />} />
                            </Route>
                        </Route>

                        {/* Create supplier */}
                        <Route path='create' element={<CreateCompany/>} />
                    </Route>
                </Route>

                {/* Calculation routes */}
                <Route path='calculations'>
                    {/* List calculations */}
                    <Route index element={<CalculationOverView />} />

                    <Route path={'hjmg/create'} element={<CreateHJMGCalculation />} />

                    <Route path=':calculationId/view' element={<ViewSwitcher />} />

                    <Route
                        path='create/template'
                        element={<EditCalculationTemplate type='add' />}
                    />

                    <Route path='create/' element={<CreateCalculation />} />

                    <Route
                        path=':copyId/edit'
                        element={
                            <CalculationOwnerSwitch
                                param={'copyId'}
                                hjmgComponent={<CreateHJMGCalculation edit={true} />}
                                pretoreComponent={<CreateCalculation edit={true} />}
                            />
                        }
                    />

                    <Route
                        path=':copyId/copy'
                        element={
                            <CalculationOwnerSwitch
                                param={'copyId'}
                                hjmgComponent={<CreateHJMGCalculation copy={true} />}
                                pretoreComponent={<CreateCalculation copy={true} />}
                            />
                        }
                    />

                    <Route
                        path=':copyId/revision'
                        element={
                            <CalculationOwnerSwitch
                                param={'copyId'}
                                hjmgComponent={<CreateHJMGCalculation revision={true} />}
                                pretoreComponent={<CreateCalculation revision={true} />}
                            />
                        }
                    />

                    <Route path='checklists/'>
                        <Route index element={<Checklists />} />

                        <Route path='create' element={<CreateUpdateChecklist type='create' />} />

                        <Route path=':checklistId'>

                            <Route
                                path='update'
                                element={<CreateUpdateChecklist type='update' />}
                            />
                        </Route>
                    </Route>

                    {/* Full catalog */}
                    <Route path='catalog'>
                        <Route index element={<Catalog />} />

                        <Route path='materials'>
                            {/* No page found here */}
                            <Route index element={<NotFound />} />

                            {/* Material item */}
                            <Route
                                path='create'
                                element={<MaterialCreateUpdate type={'create'} />}
                            />

                            {/* Material item */}
                            <Route
                                path='copy/:materialId'
                                element={<MaterialCreateUpdate type={'copy'} />}
                            />

                            {/* Material item */}
                            <Route path=':materialId'>
                                <Route index element={<Material />} />

                                <Route
                                    path='update'
                                    element={<MaterialCreateUpdate type={'update'} />}
                                />
                            </Route>
                        </Route>

                        <Route path='prices' element={<EditPrice />} />
                    </Route>

                    <Route path='settings'>
                        {/* All calculation settings */}
                        <Route index element={<CalculationSettings />} />

                        {/* Machines group */}
                        <Route path='machines'>
                            {/* Create machine */}
                            <Route path='create' element={<MachineCreateUpdate type='create' />} />

                            {/* Update machine */}
                            <Route
                                path=':machineId/update'
                                element={<MachineCreateUpdate type='update' />}
                            />

                            {/* View machine */}
                            <Route path=':machineId/view' element={<MachineView />} />
                        </Route>
                    </Route>

                    <Route path='widgets'>
                        {/* Widget collections */}
                        <Route index element={<WidgetCollection />} />

                        {/* Widget collection Create */}
                        <Route path='create/:machineId' element={<CreateFlow />} />

                        {/* Widget collection Create */}
                        <Route path='create/' element={<CreateFlow />} />

                        {/* Main widget route */}
                        <Route path=':widgetId'>
                            {/* Edit widget */}
                            <Route path='edit' element={<EditFlow />} />
                        </Route>
                    </Route>

                    <Route path='templates'>
                        <Route index element={<TemplateIndex />} />

                        <Route path='create' element={<EditCalculationTemplate type='create' />} />

                        {/* Material item */}
                        <Route
                            path=':templateId/edit'
                            element={<EditCalculationTemplate type='update' />}
                        />
                    </Route>

                    <Route path='tabs'>
                        <Route index element={<CalculationTabsOverview />} />

                        <Route path='create' element={<CreateEditCalculationTabs />} />

                        <Route
                            path=':tabsId/edit'
                            element={<CreateEditCalculationTabs />}
                        />
                    </Route>
                </Route>

                <Route path='stock' element={<StocksOverview />} />

                <Route path='warehouses'>
                    <Route index element={<WareHouses />} />

                    <Route path='create' element={<CreateWareHouse />} />

                    <Route path=':id' element={<Warehouse />} />
                </Route>

                <Route path='purchase'>

                    <Route index element={<PurchaseQuotesOverview />} />
                    <Route path='quotes'>
                        <Route index element={<PurchaseQuotesOverview />} />
                        {/* Any applied filter */}
                        <Route path='create' element={<CreatePurchaseQuote />} >
                            <Route path=':orderId' element={<CreatePurchaseQuote />} />
                        </Route>
                        <Route path=':purchaseQuoteId/edit' element={<CreatePurchaseQuote />} />
                        <Route path=':purchaseQuoteId' element={<PurchaseQuote />} />
                    </Route>
                </Route>

                {/*<Route path='purchase'>*/}
                {/*    <Route index element={<PurchaseQuotesOverview />} />*/}

                {/*    <Route path='quotes'>*/}
                {/*        <Route index element={<PurchaseQuotesOverview />} />*/}

                {/*        <Route path='create' element={<CreatePurchaseQuote forOrder={true}/>} />*/}
                {/*        /!*<Route path=':number'>*!/*/}
                {/*        /!*    <Route index element={<PurchaseQuote />} />*!/*/}

                {/*        /!*    <Route path='edit' element={<CreatePurchaseQuote />} />*!/*/}
                {/*        /!*</Route>*!/*/}
                {/*    </Route>*/}

                {/*    <Route path='orders'>*/}
                {/*        <Route index element={<PurchaseSlipsOverview />} />*/}

                {/*        <Route path='create'>*/}
                {/*            <Route index element={<CreatePurchaseSlip />} />*/}
                {/*            <Route path=':id' element={<CreatePurchaseSlip />} />*/}
                {/*        </Route>*/}

                {/*        <Route path=':number' element={<PurchaseSlip />} />*/}
                {/*    </Route>*/}
                {/*</Route>*/}

                {/* Time accounting routes */}
                <Route path='timeAccounting'>
                    {/* No page found here */}
                    <Route index element={<TimeAccounting />} />
                </Route>

                {/* Admin routes */}
                <Route path='admin'>
                    {/* Users */}
                    <Route path='users'>
                        {/* List of users */}
                        <Route index element={<Users />} />

                        {/* User details (update) */}
                        <Route path=':userId' element={<User />} />
                    </Route>

                    {/* Managers */}
                    <Route path='managers'>
                        <Route index element={<ManagersIndex />} />

                        <Route path='create' element={<CreateManager key={'create'} />} />
                        <Route path='edit/:id' element={<CreateManager key={'edit'} />} />
                    </Route>

                    {/* Roles */}
                    <Route path='roles' element={<Roles />} />

                    {/* Log */}
                    <Route path='logs'>
                        {/* List of logs */}
                        <Route index element={<Logs />} />

                        <Route path=':logId' element={<LogDetail />} />
                    </Route>

                    {/* Terminals*/}
                    <Route path='terminals'>
                        <Route index element={<Terminals />} />
                        <Route path=':terminalId' element={<Terminal />} />
                        <Route path='create' element={<Terminal />} />
                    </Route>

                    {/* Config */}
                    <Route path='config' element={<Config />} />
                </Route>

                <Route path='account' element={<Account />} />

                <Route path='planning'>
                    <Route index element={<GeneralPlanning />} />
                    <Route path='department/:type' element={<GeneralPlanning />} />
                    <Route path='personal' element={<PersonalPlanning />} />
                    <Route path=':type/:id' element={<Planning key={'planning_detail'} />} />
                    <Route path=':type' element={<Planning key={'planning_all'} />} />
                    <Route path='orders/:id/create' element={<PlanningCreator />} />
                    {/*<Route path='personnel' element={<PersonnelPlanning />} />*/}
                    <Route path='machines' element={<MachinePlanningOverview />} />
                </Route>

                {/* Not found page (Only for authenticated users) */}
                <Route path='*' element={<NotFound />} />
            </Route>
        </Route>
    )
);

function ProviderProvider() {
    return (
        <AuthProvider>
            <FetchProvider>
                <ConfigProvider>
                    <Outlet />
                </ConfigProvider>
            </FetchProvider>
        </AuthProvider>
    );
}
