import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import 'UI/App/Partials/Content/CRM/OrderConfirmations/orderConfirmation.scss';
import Recalculate from "./steps/Recalculate";
import ConfirmOrder from "./steps/ConfirmOrder";
import { toast } from "react-toastify";
import { FetchContext } from "App/Strapi/FetchContext";
import { downloadFile } from "App/Util/fetch";
import { AuthContext } from "App/Strapi/AuthProvider";
import { stringify } from "qs";
import ConfirmationPDFGenerator from "./ConfirmationPDFGenerator";
import Briefpapier_PRETORE from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE.jpg";
import Briefpapier_PRETORE_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_PRETORE_2.jpg";
import Briefpapier_HJMG from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg";
import Briefpapier_HJMG_page_2 from "UI/Assets/Images/PdfBackgrounds/Briefpapier_HJMG.jpg";
import { SpinnerOverlay } from "UI/App/Components/Spinner";
import ProjectRepository from "../../Projects/ProjectRepository";
import Grid from "UI/App/Components/Grid/Grid";

export default function CreateOrderConfirmation({ copy = false, revision = false }) {
    const [progressNotice, setProgressNotice] = useState('');
    const [activeStep, setActiveStep] = useState(1);
    const params = useParams();
    const { authAxios } = useContext(FetchContext);
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();

    // states for tabs
    const [calculationTabButtons, setCalculationTabButtons] = useState([]);

    // States for confirmation
    const [chosenAmount, setChosenAmount] = useState(null);
    const [quoteData, setQuoteData] = useState(null);
    const [chosenPDFTemplateId, setChosenPDFTemplateId] = useState(null);
    const [json, setJson] = useState([]);
    const [chosenCalculationDataId, setChosenCalculationData] = useState(<></>);
    const editorRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [formattedRows, setFormattedRows] = useState([])
    const [date, setDate] = useState([]);
    const [company, setCompany] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [confirmationCopyParent, setConfirmationCopyParent] = useState(null);

    const [price, setPrice] = useState('0');
    const [footerData, setFooterData] = useState([]);
    const [footerData2, setFooterData2] = useState([]);
    const [footerText, setFooterText] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const [shipment, setShipment] = useState('');
    const [shippingAdres, setShippingAdres] = useState('');
    const [shippingCost, setShippingCost] = useState('0');
    const [language, setLanguage] = useState('NL')
    const [signature, setSignature] = useState(null);
    const [placeOfOrigin, setPlaceOfOrigin] = useState('Dordrecht');
    const [introLine, setIntroLine] = useState('');

    const [calculations, setCalculations] = useState([]);
    const [spinnerState, setSpinnerState] = useState(true);
    const [chosenAmounts, setChosenAmounts] = useState([]);

    const [backgroundOne, setBackgroundOne] = useState(null)
    const [backgroundTwo, setBackgroundTwo] = useState(null)

    const [owner, setOwner] = useState(null);
    const [orderReff, setOrderReff] = useState(undefined);

    useEffect(() => {
        load();
    }, []);

    // get the customerReff from the calculation
    useEffect(() => {
        setOrderReff(calculations[0]?.data?.checklist?.find((el) => el.name === 'customerReference')?.value)
    }, [calculations])

    useEffect(() => {
        // flatten array
        let flatRows = formattedRows.flat();
        // try to find the reff
        let foundReff = flatRows?.find((el) => ['kenmerk klant', 'your reference'].includes(el.label.toLowerCase()))?.content

        // return if we have NO reff
        if (foundReff === undefined) return;
        // set reff
        setOrderReff(foundReff);
    }, [formattedRows])

    // Update price based on chosen amount
    useEffect(() => {
        let totalPrice = 0;
        Object.keys(chosenAmounts).forEach(function (calculationId, amount) {
            amount = chosenAmounts[calculationId]

            for (const calculation of calculations) {
                // If not current calculation skip
                if (calculation.id !== parseInt(calculationId)) continue;

                // If summary is undefined skipp price
                if (calculation.data.summary[amount] === undefined) continue;

                totalPrice += calculation.data.summary[amount].quote
            }
        });

        setPrice(totalPrice.toFixed(2))
    }, [chosenAmounts])

    async function load() {
        if (copy || revision) {
            authAxios.get(`/quotes/confirmations/${params.confirmationId}?${stringify({
                populate: {
                    orders: {
                        populate: {
                            calculation: {
                                populate: {
                                    owner: true,
                                    company: true,
                                    contactPerson: true,
                                    purchaseQuotes: true,
                                }
                            }
                        }
                    },
                    quote: {
                        populate: {
                            company: {
                                populate: {
                                    address: true
                                }
                            },
                            confirmation: true,
                            contactPerson: true,
                            calculations: true,
                            priceData: true,
                        }
                    }
                }
            })}`).then(({ data }) => {
                setOwner(data.data.quote.calculations[0].owner);
                if (data.data.quote.calculations[0].owner === 'pretore') {
                    setBackgroundOne(Briefpapier_PRETORE);
                    setBackgroundTwo(Briefpapier_PRETORE_page_2);
                } else {
                    setBackgroundOne(Briefpapier_HJMG);
                    setBackgroundTwo(Briefpapier_HJMG_page_2);
                }

                // Get calculations from orders
                let calculations = [];
                for (const order of data.data.orders) {
                    calculations.push(order.calculation)
                }

                setConfirmationCopyParent(data);
                setCalculations(calculations);
                setQuoteData(data.data.quote)

                if (calculations[0].owner === 'hjmg') {
                    // If HJMG load hjmg calculation else load Pretore calculations
                    if (calculations[0].owner === 'hjmg') {
                        let totalPrice = 0;

                        for (const priceData of data.data.quote.priceData) {
                            totalPrice += priceData.price
                        }

                        setPrice(totalPrice)

                    }
                } else {
                    // Load amounts
                    for (const calculation of calculations) {
                        for (const amount of calculation.data.amounts) {
                            let amounts = { ...chosenAmounts }
                            // Update chosen amounts
                            amounts[calculation.id] = amount;
                            setChosenAmounts(amounts);
                        }
                    }
                }

                setSpinnerState(false);
                setActiveStep(0);
            }).catch((exception) => {
                console.error(exception);
            });
        } else {
            authAxios.get(`/quotes/${params.quoteId}?${stringify({
                populate: {
                    priceData: true,
                    calculations: {
                        populate: {
                            company: true,
                            contactPerson: true,
                            purchaseQuotes: true,
                        }
                    },
                    company: {
                        populate: {
                            address: true
                        }
                    },
                    contactPerson: true,
                }
            })}`).then(({ data }) => {
                // Set backgrounds
                setOwner(data.data.calculations[0].owner);
                if (data.data.calculations[0].owner === 'pretore') {
                    setBackgroundOne(Briefpapier_PRETORE);
                    setBackgroundTwo(Briefpapier_PRETORE_page_2);
                } else {
                    setBackgroundOne(Briefpapier_HJMG);
                    setBackgroundTwo(Briefpapier_HJMG_page_2);
                }

                setCalculations(data.data.calculations);
                setQuoteData(data.data)

                // If HJMG load hjmg calculation else load Pretore calculations
                if (data.data.calculations[0].owner === 'hjmg') {
                    let totalPrice = 0;

                    for (const priceData of data.data.priceData) {
                        totalPrice += priceData.price
                    }

                    setPrice(totalPrice)

                } else {
                    // Load amounts
                    for (const calculation of data.data.calculations) {
                        for (const amount of calculation.data.amounts) {
                            let amounts = { ...chosenAmounts }
                            // Update chosen amounts
                            amounts[calculation.id] = amount;
                            setChosenAmounts(amounts);
                        }
                    }
                }

                setSpinnerState(false);
                setActiveStep(0);
            }).catch((exception) => {
                console.error(exception);
            });
        }
    }

    useEffect(() => {
        // Load data rows on quote load
        if (quoteData !== null) {
            loadBasicData();
        }
    }, [quoteData])

    async function loadBasicData() {
        let rows = [
            { label: 'Conf. no.', content: 'CONF' }
        ];

        let tabButtons = [];
        let buttonKey = 1;
        let calculations = quoteData.calculations;
        let company = quoteData.company;
        let contactPerson = quoteData.contactPerson;

        // Reverse to start with the lowest number
        for (const calculation of calculations.reverse()) {

            // If owner pretore load a certain way
            if (calculation.owner === 'pretore') {
                // load calculation tabs
                buttonKey++;
                tabButtons.push({
                    key: buttonKey,
                    name: calculation.number
                });

                for (const checklistItem of calculation.data.checklist) {
                    if (checklistItem.value === '') {
                        continue;
                    }

                    rows.push({
                        label: (language?.toLowerCase() === 'nl' ? checklistItem.dutchName : checklistItem.englishName) ?? checklistItem.name,
                        content: checklistItem.value
                    });
                }
            } else {
                for (const checklistItem of calculation.data.checklistData) {
                    if (checklistItem.value === '') {
                        continue;
                    }

                    rows.push({
                        label: (language?.toLowerCase() === 'nl' ? checklistItem.dutchName : checklistItem.englishName) ?? checklistItem.name,
                        content: checklistItem.value
                    });
                }
            }


            // If more than 1 calculation add emtpy row
            if (calculations > 1) {
                rows.push({ label: '', content: '' })
            }
        }

        setCalculationTabButtons(tabButtons);

        setRowData(rows)

        // Load company address
        if (company !== null && company !== undefined) {
            let adres = '';
            adres += `${company.address.addressLine1}, ${company.address.postalCode} ${company.address.city} `

            setShippingAdres(adres);
        }

        setContactPerson(contactPerson);
        setCompany(company);

        setLanguage(calculations[0].language)

        // Get all copied data from confirmation if copy
        if ((copy || revision) && quoteData?.confirmation) {
            setShipment(quoteData.confirmation.data.data.shipment);
            setShippingAdres(quoteData.confirmation.data.data.shippingAdres);
            setShippingCost(quoteData.confirmation.data.data.shippingCost);
            setDeliveryTime(quoteData.confirmation.data.data.deliveryTime);

            setPrice(quoteData.confirmation.data.data.price);
            setDate((Date.now()))

            setChosenAmount(quoteData.confirmation.data.data.chosenAmount);
        }

        setIntroLine(
            language === 'NL'
                ? 'Hartelijk dank voor uw order. Onderstaand de specificatie en bevestiging van uw order.\nCheck deze specificatie goed en bij akkoord zien wij uw reactie tegemoet.'
                : 'Thank you very much for your order. Below is the specification and confirmation of your order.\nPlease check this specification carefully and upon approval we look forward to your response.'
        )

        // Load footer data
        setFooterData([
            {
                label: language === 'NL' ? 'Prijs' : 'Price',
                content: language === 'NL' ? 'EX BTW' : 'EX VAT'
            },
            {
                label: language === 'NL' ? 'Betaling' : 'Payment',
                content: language === 'NL' ? '50% bij order en 50% voor transport' : '50% at order and 50% before shipping'
            },
            {
                label: language === 'NL' ? 'Transport' : 'Shipment',
                content: language === 'NL' ? 'Af-fabriek' : 'Ex Works'
            },
            // i am geusing if shipment/transport exists this is uneeded?
            // {
            //     label: language === 'NL' ? 'Franco' : 'Free',
            //     content: language === 'NL' ? 'Af fabriek' : 'EX works'
            // }
        ]);

        setFooterData2([
            {
                label: language === 'NL' ? 'Betaling' : 'Invoice',
                content: language === 'NL' ? 'Transport wordt door transporteur rechtstreeks aan u gefactureerd.' : 'Shipping will be billed directly to you by carrier.'
            },
        ]);

        setFooterText(
            language === 'NL'
                ? 'Wij danken u nogmaals voor de order en het vertrouwen. Met een passie voor het maken van unieke producten van de hoogste kwaliteit, vertrouwen we erop dat we u een goed aanbod hebben gedaan.'
                : `We thank you again for your order and trust. With a passion for creating unique products of the highest quality, we are confident that we have made you a good offer.`
        );
    }

    useEffect(() => {
        // Skip if quote id is undefined happens if confirmation is copied
        if (params.quoteId !== undefined) {
            authAxios.get(`/quotes/${params.quoteId}?populate[0]=company.address&populate[1]=calculations&populate[2]=contactPerson&populate[3]=company.contactPersons`).then((quote) => {
                // Get language form first calculations (all calculations must have the same language)
                const quoteLanguage = quote.data.data.calculations[0].language;

                authAxios.get(`/config/pdf-templates?filters[type]=QuoteConfirmation`).then((data) => {

                    data.data.data.map((template) => {
                        if (template.language === quoteLanguage) {
                            setChosenPDFTemplateId(template.id)
                        }
                    })
                }).catch((exception) => {
                    console.error(exception);
                    toast.error('Er ging iets mis met het ophalen van PDF templates.')
                });

            }).catch((exception) => {
                console.error(exception);
            });
        }
    }, [params.quoteId])

    useEffect(() => {
        setProgressNotice('');
    }, [chosenAmount])

    // get authstate user signature image
    useEffect(() => {
        if (authState?.user?.signature?.id) {
            // get the image as a dataURL
            downloadFile(authAxios, authState.user.signature.id, 'base64').then((base64) => {
                setSignature(`data:${authState.user.signature.mime};base64,${base64}`);
            });
        } else {
            setSignature(false);
        }
    }, [authAxios, authState.user.signature?.id, authState.user.signature?.mime]);

    const getShippingData = () => {
        const shippingData = {
            total: chosenAmount,
            boxes: null,
            pallets: null
        }

        // If undefined return empty shipping
        if (json?.data?.data?.templateData?.data === undefined) {
            return shippingData
        }

        // Loop machines until 'Verpakken' is found
        for (const [machineKey, machine] of Object.entries(json?.data?.data?.templateData?.data)) {
            if (machine.name !== undefined) {
                if (machine.name.toLowerCase() === 'verpakken') {
                    // Loop widgets until total material is found
                    for (const widget of machine.widgets) {
                        if (widget.data.title.toLowerCase().includes('aantal') && widget.data.title.toLowerCase().includes('materiaal')) {
                            // Get value from json
                            shippingData.boxes = json?.data?.data?.totalResultJson[widget.data.title][chosenAmount];
                        }
                    }
                }
            }
        }

        return shippingData;
    }

    async function storeConfirmation({ currentTarget }) {
        // disable button
        currentTarget.disabled = true;
        
        if (quoteData?.company?.type.toLowerCase() !== "klant") {
            toast.error("Kan alleen een order aanmaken voor klanten.")
            
            // re-enable button
            currentTarget.disabled = false;
            
            return;
        }

        let project = await new ProjectRepository(null, authAxios).createProject(null)

        // If copy get id from copy else use params quote id
        if (!copy && !revision) {


            // Update quote status
            await authAxios.put(`/quotes/${params.quoteId}?populate=pdfTemplate`, {
                "data": {
                    "status": "geaccepteerd"
                }
            }).then(({ data }) => {
                toast.success(`Offerte: ${data.data.number} status omgezet naar geaccepteerd`);
            });
        }

        await createConfirmation(project, currentTarget);
    }

    async function createConfirmation(project, currentTarget) {
        let formattedForStorage = [];
        for (const rowsKey in formattedRows) {
            for (const row of formattedRows[rowsKey]) {
                formattedForStorage.push({
                    title: row.label,
                    content: row.content,
                    page: rowsKey
                })
            }
        }

        // Create description
        let description = '';
        if (copy) {
            description = `Kopie van : ${confirmationCopyParent.data.number}`;
        } else if (revision) {
            description = `Revisie van : ${confirmationCopyParent.data.number}`;
        } else {
            description = quoteData?.data?.data?.description;
        }

        // Build amounts object
        let amounts = [];
        let calculations = [];
        Object.keys(chosenAmounts).forEach(function (calculationId, amount) {
            amounts.push({
                calculation: calculationId,
                amount: chosenAmounts[calculationId]
            });
            calculations.push(calculationId)
        });

        // Build quote data
        let quote = null;
        if (copy || revision) {
            quote = quoteData.id;
        } else {
            quote = params.quoteId
        }

        let json = {
            data: {
                quote: parseInt(quote),
                pdfTemplate: chosenPDFTemplateId,
                content: '', // For backwards compatibility
                status: 'Open',
                description: description,
                price: price,
                shippingCost: shippingCost,
                shippingAdres: shippingAdres,
                shipment: shipment,
                deliveryTime: deliveryTime,
                data: formattedForStorage,
                chosenAmounts: amounts,
                calculations: calculations
            },
        }

        if (revision) {
            json.data.copiedFrom = quoteData.confirmation.data.data.id;
        }

        // return
        authAxios.post(`/quotes/confirmations`, json).then(({ data: returnData }) => {
            toast.success(`Order bevestiging opgeslagen!`);

            // add quote conf to project
            new ProjectRepository(project, authAxios).addConfirmationQuoteToProject(returnData.data)

            updateConfirmationNumber(returnData, json, project, currentTarget);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het bewerken van order bevestiging`);
            
            // re-enable button
            currentTarget.disabled = false;
        });
    }

    async function updateConfirmationNumber(returnData, json, project, currentTarget) {

        // Add number to pdf
        for (const page of formattedRows) {
            for (const row of page) {
                if (row.label === 'Conf. no.') {
                    row.content = returnData.data.number
                }
            }
        }

        json = {
            data: {
                pdfBase64: await new ConfirmationPDFGenerator({ page1: backgroundOne, page2: backgroundTwo }, formattedRows, quoteData, company, owner, contactPerson, shippingCost, shippingAdres, shipment, deliveryTime, price, false, language, date, signature, placeOfOrigin, introLine, footerData, footerData2, footerText,chosenAmount).getPDF()
            }
        }

        await authAxios.put(`/quotes/confirmations/${returnData.data.id}`, json).then(({ data: returnData }) => {
            createOrders(returnData, project);
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Er ging iets mis met het updaten van order bevestiging`);
            
            // re-enable button
            currentTarget.disabled = false;
        });
    }

    async function createOrders(returnData, project) {
        let orderIds = [];

        if (owner === 'pretore') {
            // used for adding to project
            // Create orders for calculations
            Object.keys(chosenAmounts).forEach(function (calculationId, amount) {
                amount = chosenAmounts[calculationId]
                let json = {
                    data: {
                        quoteConfirmation: returnData.data.id,
                        description: quoteData?.data?.data?.description,
                        shipping: getShippingData(),
                        status: 'binnengekomen',
                        chosenAmount: parseInt(amount),
                        calculation: parseInt(calculationId),
                        company: company,
                        contactPerson: contactPerson,
                        quote: quoteData.id,
                        customerReference: orderReff
                    }
                }

                authAxios.post(`/orders`, json).then(({ data }) => {
                    setQuoteStatus();
                    toast.success(`Order aangemaakt!`);
                    orderIds.push(data.data.id)

                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het opslaan van een order!`);
                });
            })
        } else {
            // Crete order for each calculation
            for (const calculation of calculations) {
                let json = {
                    data: {
                        quoteConfirmation: returnData.data.id,
                        description: quoteData?.data?.data?.description,
                        shipping: getShippingData(),
                        status: 'binnengekomen',
                        calculation: parseInt(calculation.id),
                        company: company,
                        contactPerson: contactPerson,
                        quote: quoteData.id,
                        customerReference: orderReff
                    }
                }

                authAxios.post(`/orders`, json).then(({ data }) => {
                    setQuoteStatus();
                    toast.success(`Order aangemaakt!`);
                    orderIds.push(data.data.id)

                }).catch((exception) => {
                    console.error(exception);
                    toast.error(`Er ging iets mis met het opslaan van een order!`);
                });
            }
        }

        setTimeout(() => {
            // add order to project
            new ProjectRepository(project, authAxios).addOrdersToProject(orderIds)

            navigate(`/orders/confirmation/${returnData.data.id}`);
        }, 500)
    }
    async function setQuoteStatus(status = "order") {
        await authAxios.put(`quotes/${params.quoteId}`, {
            data: {
                status: status
            }
        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });

        // format all the id of the calculation
        let calculationsToUpdate = quoteData?.calculations.map((ell) => { return { id: { $eq: ell.id } } }) ?? {}

        // set all the linked calculations to be the same, 'order' by default
        await authAxios.post(`calculations/update-many-statuses`, {
            data: {
                status: status
            },
            where: {
                $or: [...calculationsToUpdate]
            }


        }).catch((reason) => {
            console.error(reason);
            toast.error(`Er is iets mis gegaan bij het updaten van de status. ${reason?.response?.status && `(${reason.response.status})`}`)
        });
    }
    return (
        <>
            <SpinnerOverlay visible={spinnerState}>
                {/* Add tab buttons */}
                <Grid columns={calculationTabButtons.length + 1}>
                    {calculationTabButtons.map((button, number) => {
                        return (
                            <div key={`button-${number}`} onClick={() => setActiveStep(number)} className={`confirmation-progress-item  ${activeStep > number ? 'confirmation-progress-item-done' : ''} ${activeStep === number ? 'confirmation-progress-item-active' : ''}`} stepid={number}>
                                {button.name}
                            </div>
                        )
                    })}

                    <div onClick={() => setActiveStep(calculationTabButtons.length)} className={`confirmation-progress-item  ${activeStep > 2 ? 'confirmation-progress-item-done' : ''} ${activeStep === 2 ? 'confirmation-progress-item-active' : ''}`} stepid="2">
                        Controleren
                    </div>
                </Grid>
                {/* Add tabs */}
                {calculationTabButtons.map((button, number) => {
                    return <div key={`calc-${number}`} className="progress-container" style={{ display: (activeStep === number ? 'block' : 'none') }}>
                        <Recalculate
                            chosenAmounts={chosenAmounts}
                            setChosenAmounts={setChosenAmounts}
                            calculation={calculations[number]}
                            revision={revision}
                            copy={copy}
                            chosenCalculationDataId={chosenCalculationDataId}
                            setChosenCalculationData={setChosenCalculationData}
                            setJson={setJson}
                            quoteData={quoteData}
                            setQuoteData={setQuoteData}
                            activeStep={activeStep}
                            chosenAmount={chosenAmount}
                            setExternalChosenAmount={setChosenAmount}
                            quoteId={params.quoteId}
                            confirmationId={params.confirmationId}
                            setSpinnerState={setSpinnerState}
                            calculationNumber={number}
                        />
                    </div>
                })}
                {/* HTML for step two */}
                <div className="progress-container" style={{ display: (activeStep === calculationTabButtons.length ? 'block' : 'none') }}>
                    <div className="customer-data-container step-container">
                        <ConfirmOrder
                            revisionedCalculations={calculations}
                            language={language}
                            shippingCost={shippingCost}
                            setShippingCost={setShippingCost}
                            shippingAdres={shippingAdres}
                            setShippingAdres={setShippingAdres}
                            shipment={shipment}
                            setShipment={setShipment}
                            deliveryTime={deliveryTime}
                            setDeliveryTime={setDeliveryTime}
                            price={price}
                            setPrice={setPrice}
                            date={date}
                            setDate={setDate}
                            chosenAmount={chosenAmount}
                            chosenCalculationId={chosenCalculationDataId}
                            company={company}
                            contactPerson={contactPerson}
                            formattedRows={formattedRows}
                            setFormattedRows={setFormattedRows}
                            rowData={rowData}
                            setRowData={setRowData}
                            saveConfirmation={storeConfirmation}
                            editorRef={editorRef}
                            chosenPDFTemplateId={chosenPDFTemplateId}
                            activeStep={activeStep}
                            json={json}
                            quoteData={quoteData}
                            quoteId={params.quoteId}
                            signature={signature}
                            owner={owner}
                            placeOfOrigin={placeOfOrigin}
                            setPlaceOfOrigin={setPlaceOfOrigin}
                            introLine={introLine}
                            setIntroLine={setIntroLine}
                            footerData={footerData}
                            setFooterData={setFooterData}
                            footerData2={footerData2}
                            setFooterData2={setFooterData2}
                            footerText={footerText}
                            setFooterText={setFooterText}
                        />
                    </div>
                </div>
            </SpinnerOverlay>
        </>
    )

}
