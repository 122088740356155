import './HJMGCalculation.scss';
import ActivityTab from "UI/App/Partials/Content/Calculations/HJMG/steps/ActivityTab/ActivityTab";
import {createContext, useContext, useEffect, useState} from "react";
import TabHandle from "UI/App/Partials/Content/Calculations/HJMG/steps/TabHandle";
import PrintTab from "UI/App/Partials/Content/Calculations/HJMG/steps/PrintTab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FetchContext } from "App/Strapi/FetchContext";
import CalculationResultTab from "UI/App/Partials/Content/Calculations/HJMG/steps/CalculationResultTab";
import InfoTab from "UI/App/Partials/Content/Calculations/HJMG/steps/InfoTab";
import {SpinnerOverlay} from "UI/App/Components/Spinner";
import qs from "qs";
import {toast} from "react-toastify";
import ChecklistTab from "UI/App/Partials/Content/Calculations/HJMG/steps/ChecklistTab";
import IF from "UI/App/Components/Conditional/IF";
import Icon from "UI/App/Components/Icon/Icon";
import useWindowDimensions from "App/Util/useWindowDimensions";
import WerkderdenTabHJMG from "UI/App/Partials/Content/Calculations/HJMG/steps/WerkderdenTabHJMG";
import {getPurchaseData} from "UI/App/Partials/Content/Calculations/HJMG/steps/CalculationResultTab/PurchaseResultBlock";

export const CalculationProviderContext = createContext({});

export const _PROJECT_MANAGEMENT_RATE = 90;

const _ACTIVITIES= {
    "Strategie": {
        "order": 0,
        "projectManagementPercentage": 5,
        "hourlyRate": 100
    },
    "Branding": {
        "order": 1,
        "projectManagementPercentage": 5,
        "hourlyRate": 90
    },
    "Content": {
        "order": 2,
        "projectManagementPercentage": 10,
        "hourlyRate": 70
    },
    "Studio": {
        "order": 3,
        "projectManagementPercentage": 7.5,
        "hourlyRate": 70
    },
    "Online": {
        "order": 4,
        "projectManagementPercentage": 10,
        "hourlyRate": 70
    },
    "Development": {
        "order": 5,
        "projectManagementPercentage": 15,
        "hourlyRate": 80
    },
    "Service": {
        "order": 6,
        "projectManagementPercentage": null,
        "isRecurring": true,
        "options": [
            {
                "label": "Hosting",
                "options": [
                    {
                        "label": "Hostingpakket small",
                        "value": "small",
                        "isChargedHourly": false,
                        "cost": {
                            "monthly": 10,
                            "yearly": 100
                        }
                    },
                    {
                        "label": "Hostingpakket medium",
                        "value": "medium",
                        "isChargedHourly": false,
                        "cost": {
                            "monthly": 20,
                            "yearly": 200
                        }
                    },
                    {
                        "label": "Hostingpakket large",
                        "value": "large",
                        "isChargedHourly": false,
                        "cost": {
                            "monthly": 30,
                            "yearly": 300
                        }
                    },
                    {
                        "label": "Hostingpakket extra large",
                        "value": "extra large",
                        "isChargedHourly": false,
                        "cost": {
                            "monthly": 40,
                            "yearly": 400
                        }
                    },
                ]
            },
            {
                "label": "Onderhoud",
                "value": "maintenance",
                "cost": 60,
                "isChargedHourly": true,
                "projectManagementPercentage": 7.5
            },
            {
                "label": "SEO",
                "value": "SEO",
                "cost": 70,
                "isChargedHourly": true,
                "projectManagementPercentage": 15
            },
            {
                "label": "Vrij invulbaar",
                "value": "custom",
                "cost": 70,
                "isChargedHourly": null,
                "isCustom": true,
                "projectManagementPercentage": 7.5
            },
        ]
    }
};

export default function CreateHJMGCalculation({edit = false,copy = false,revision = false}) {
    const params = useParams();
    const locationParams = useLocation();
    const [results, setResults] = useState({
        margin: 20,
    });

    const [customer, setCustomer] = useState(null);
    const [language, setLanguage] = useState(null);
    const [printData, setPrintData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [contactPerson, setContactPerson] = useState(null);
    const [checklistData, setChecklistData] = useState([]);
    const [checklistTemplateId, setChecklistTemplateId] = useState(null);
    const [calculationData, setCalculationData] = useState({});
    const [customerReference, setCustomerReference] = useState(null);
    const [projectDescription, setProjectDescription] = useState(null);
    const [isCopy, setIsCopy] = useState(false);
    const [calculationNumber, setCalculationNumber] = useState(null);
    const [calculationId, setCalculationId] = useState(params?.copyId ?? null);
    const [purchaseQuoteData, setPurchaseQuoteData] = useState(null);
    const navigate = useNavigate();

    const [activities,setActivities] = useState(null);

    // Used to try and open a new tab.
    const [activeTab, setActiveTab] = useState('information');
    const [tabFeedback, setTabFeedback] = useState(null);

    const { authAxios } = useContext(FetchContext);
    const { width } = useWindowDimensions()

    useEffect(() => {
        load();
        if (params.copyId) {
            const query = qs.stringify({
                populate: {
                    contactPerson: {
                        fields: [
                            'id'
                        ]
                    },
                    company: {
                        fields: [
                            'id',
                        ]
                    },
                    purchaseQuotes: {
                        populate: {
                            quantities: true,
                            supplier: {
                                fields: ['id']
                            },
                            contactPerson: {
                                fields: ['id']
                            }
                        }
                    }
                }
            });

            authAxios.get(`/calculations/${params.copyId}?${query}`).then(({ data }) => {
                const calc = data.data;

                setLanguage(calc.language?.toLowerCase());
                setCustomer(calc.company?.id);
                setPrintData(calc.data.printData);
                setContactPerson(calc.contactPerson?.id);
                setChecklistData(calc.data?.checklistData ?? []);
                setChecklistTemplateId(calc.data?.checklistTemplateId ?? null);
                setCustomerReference(calc.customerReference);
                setCalculationNumber(calc.number);
                setProjectDescription(calc.projectDescription);
                setCalculationData(Object.fromEntries(Object.entries(calc.data.calculationData).sort(([ka, a], [kb, b]) => a.order - b.order)));
                setPurchaseQuoteData(calc.purchaseQuotes);

                setTimeout(() => {
                    setResults(calc.data?.results ?? {});
                }, 200);

                if (calc.status !== 'concept') {
                    setIsCopy(true);
                    setCustomerReference(prev => prev + ' (kopie)');
                    setActiveTab('information');
                } else {
                    setActiveTab('information');
                }

                setIsLoading(false);
            }).catch((e) => {
                console.error(e);
                toast.error("Er is iets fout gegaan bij het ophalen van de calculatie!");
            })
        } else {
            setCalculationData(activities);
            setIsLoading(false);
        }
    }, [activities]);

    useEffect(() => {
        if (activeTab !== 'result' && width < 1500) {
            document.querySelector('.hjmg__calculation__tab-bar__handles button[data-active=true]').scrollIntoView({behavior: 'smooth', inline: 'center', block: 'nearest'});
        }
    }, [activeTab]);

    useEffect(() => {
        if (locationParams?.state?.companyId) {
            setCustomer(locationParams?.state?.companyId)
        }
    }, []);

    async function load() {
        authAxios.get(`calculation-setting?populate=owner&filters[owner][name][$eq]=HJMG`).then(({ data }) => {
            setCalculationData(data.data[0].data)

        }).catch((e) => {
            console.error(e);
            toast.error("Er is iets fout gegaan bij het ophalen van de calculatie!");
        })
    }

    function openTab(tabId) {

        if (tabId !== 'information') {
            if (!customer) {
                setTabFeedback('Selecteer eerst een klant!');
                return;
            }

            if (!contactPerson) {
                setTabFeedback('Selecteer eerst een contactpersoon!');
                return;
            }

            if (!language) {
                setTabFeedback('Selecteer eerst een taal!');
                return;
            }

            if (!customerReference) {
                setTabFeedback('Vul eerst een klantreferentie in!');
                return;
            }
        }

        // clear feedback
        setTabFeedback(null);

        setActiveTab(tabId);
    }

    function registerEntry(activity, entry) {
        setCalculationData(prev => {
            if (prev[activity].entries === undefined) {
                prev[activity].entries = [];
            }

            prev[activity].entries.push(entry);

            return {...prev};
        })
    }

    function updateEntry(activity, index, entry) {
        setCalculationData(prev => {
            prev[activity].entries[index] = entry;

            return {...prev};
        })
    }

    function removeEntry(name, index) {
        setCalculationData(prev => {
            prev[name]?.entries?.splice(index, 1);

            return {...prev};
        })
    }

    function saveCalculation(redirect = false) {
        if(language === null){
            return
        }

        const purchaseData = getPurchaseData();

        const calculation = {
            company: customer,
            contactPerson,
            language: language.toUpperCase(),
            customerReference,
            projectDescription: projectDescription,
            data: {
                checklistData,
                checklistTemplateId,
                calculationData,
                purchaseData,
                printData,
                results,
            },
            owner: 'hjmg'
        }

        // If edit put else post
        if(edit || calculationId !== null){
            authAxios.put(`/calculations/${calculationId}`, {
                data: calculation
            }).then(({ data }) => {
                toast.success("Calculatie bijgewerkt!");

                if(redirect){
                    navigate(`/calculations/${data.data.id}/view`);
                }
            }).catch((err) => {
                console.error(err);
                toast.error("Er is iets fout gegaan bij het opslaan van de calculatie!");
            });

        } else{
            // If revision add copiedFrom id
            if(revision){
                calculation.copiedFrom = calculationId
            }

            authAxios.post('/calculations', {
                data: calculation
            }).then(({ data }) => {
                toast.success("Calculatie opgeslagen!");
                setIsCopy(false);
                setCalculationId(data.data.id);
                setCalculationNumber(data.data.number);
                window.history.pushState({}, '', `/calculations/${data.data.id}/edit`);

                if(redirect){
                    navigate(`/calculations/${data.data.id}/view`);
                }
            }).catch((err) => {
                console.error(err);
                toast.error("Er is iets fout gegaan bij het opslaan van de calculatie!");
            });
        }
    }

    function nextTab() {
        switch(activeTab) {
            case 'information':
                openTab('checklist');
                break;
            case 'checklist':
                openTab(0);
                break;
            case Object.keys(activities).length - 1:
                openTab('print');
                break;
            case 'print':
                openTab('result');
                break;
            case 'result':
                openTab('result');
                break;
            default:
                openTab(activeTab + 1);
                break;
        }
    }

    function prevTab() {
        switch(activeTab) {
            case 'information':
                openTab('information');
                break;
            case 'checklist':
                openTab('information');
                break;
            case 'print':
                openTab(Object.keys(activities).length - 1);
                break;
            case 0:
                openTab('checklist');
                break;
            case 'result':
                openTab('print');
                break;
            default:
                openTab(activeTab - 1);
                break;
        }
    }

    return (
        <SpinnerOverlay visible={isLoading}>
            <CalculationProviderContext.Provider value={{
                activeTab: activeTab,
                openTab: openTab,
                results,
                setResults,
                language,
                setLanguage,
                customer,
                setCustomer,
                contactPerson,
                setContactPerson,
                customerReference,
                setCustomerReference,
                projectDescription,
                setProjectDescription,
                printData,
                setPrintData,
                checklistData,
                setChecklistData,
                checklistTemplateId,
                setChecklistTemplateId,
                calculationData,
                setCalculationData,
                isCopy,
            }}>
                <div className={'hjmg__calculation'}>

                    <div className={'hjmg__calculation__tab-bar'}>
                        <button className={'btn btn--transparent prev-tab'} onClick={prevTab}><Icon name={'arrow-left'} /></button>
                        <div className={'hjmg__calculation__tab-bar__handles'}>
                            <TabHandle key={'information'} label={'Informatie'} active={activeTab === 'information'} openTab={() => openTab('information')} />
                            <TabHandle key={'checklist'} label={'Checklist'} active={activeTab === 'checklist'} openTab={() => openTab('checklist')} />

                            {calculationData !== null ? Object.entries(calculationData).map(([name, activity], index) =>
                                <TabHandle key={index} label={name}  active={index === activeTab} openTab={() => openTab(index)} />
                            ) : <></>}
                            <TabHandle key={'print'} label={'Print (Pretore)'} active={activeTab === 'print'} openTab={() => openTab('print')} />
                            <TabHandle key={'purchase'} label={'Inkoop'} active={activeTab === 'purchase'} openTab={() => openTab('purchase')} />
                        </div>
                        <TabHandle key={'result'} label={'Resultaat'} active={activeTab === 'result'} openTab={() => openTab('result')} />
                        <button className={'btn btn--transparent next-tab'} onClick={nextTab}><Icon name={'arrow-right'} /></button>
                    </div>

                    <div className={'hjmg__calculation__tab-container'}>
                        <IF condition={tabFeedback !== null}>
                            <span className={`hjmg__calculation__feedback`}>{tabFeedback}</span>
                        </IF>

                        {/* Information tab: Customer, contact person, language, customer reference */}
                        <InfoTab />
                        {/* Checklist tab. General information about the order */}
                        <ChecklistTab />
                        {/* Activities */}
                        {calculationData !== null ? Object.entries(calculationData).map(([name, activity], index) =>
                            <ActivityTab
                                key={index}
                                index={index}
                                name={name}
                                activity={activity}
                                registerEntry={registerEntry}
                                updateEntry={updateEntry}
                                removeEntry={removeEntry}
                            />
                        ): <></>}
                        {/* The purchase tab */}
                        <WerkderdenTabHJMG
                            checklistData={checklistData}
                            purchaseQuoteData={purchaseQuoteData}
                            calculationId={calculationId}
                        />
                        {/* The print tab */}
                        <PrintTab
                            printData={printData}
                            setPrintData={setPrintData}
                            customerId={customer}
                        />
                        {/* The calculation result */}
                        <CalculationResultTab
                            calculationNumber={calculationNumber}
                            activeTab={activeTab}
                            results={results}
                            setResults={setResults}
                            calculationData={calculationData}
                            printData={printData}
                            setPrintData={setPrintData}
                            saveCalculation={saveCalculation}
                            checklistData={checklistData}
                            language={language}
                        />
                    </div>
                </div>
            </CalculationProviderContext.Provider>
        </SpinnerOverlay>
    )
}
