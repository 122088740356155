import Button from 'UI/App/Components/Button/Button';
import FancyInput from 'UI/App/Components/Form/fancyInput';
import Popup, {closePopup} from 'UI/App/Components/Popup/Popup';
import {useCallback, useContext, useEffect, useState} from 'react';
import {FetchContext} from 'App/Strapi/FetchContext';
import {toast} from 'react-toastify';
import User from "App/Strapi/User";
import {stringify} from 'qs';

export default function AddMachineIssuePopup({machineId,machine,setMachine,setEditableIssue, editableIssue}) {
    const defaultCurrentIssueState = {
        reportedBy: User.get().id,
        reportedAt: new Date(),
        reportedIssue: '',
        issueStatus: 'Staat stil',
        issueSolution: '',
        resolvedAt: null,
        resolvedBy: null,
    }

    const {authAxios} = useContext(FetchContext);
    const [currentIssue,setCurrentIssue] = useState(defaultCurrentIssueState)

    const statusOptions = [
            'Staat stil',
            'Kan draaien maar is beperkt',
            "Draait op normale snelheid",
            "Preventief onderdeel vervangen",
            "Onderhoud",
        ]

    useEffect(() => {
        if (editableIssue !== undefined && editableIssue !== null) {
            setCurrentIssue(editableIssue)
        }else{
            setCurrentIssue(defaultCurrentIssueState)
        }
    }, [editableIssue]);

    async function submitIssue(e) {
        e.preventDefault();

        // Update issue dates etc
        if(currentIssue.issueSolution !== ""){
            if(currentIssue.resolvedAt === null){
                currentIssue.resolvedAt = new Date();
                currentIssue.resolvedBy = User.get().id;
            }
        }else{
            currentIssue.resolvedAt = null;
            currentIssue.resolvedBy = null;
        }

        // If the issue is new, add it to the machine data
        if(currentIssue.id === undefined){

            // Push the new issue to the machine data
            machine.malfunctions.push(currentIssue)
        }else{
            // update the issue in machine data
            machine.malfunctions = machine.malfunctions.map((issue) => {
                if(issue.id === currentIssue.id){
                    return currentIssue
                }
                return issue
            })
        }

        await updateMachine(machine);
    }

    async function updateMachine(data){
        // query to pop the return data
        const query = stringify({
            populate: {
                malfunctions: {
                    populate: {
                        reportedBy: {
                            fields: ["username"]
                        },
                        resolvedBy: {
                            fields: ["username"]
                        }
                    }
                }
            }
        });

        await authAxios.put(`/calculations/resources/machines/${machineId}?${query}`, {
            data
        }).then(({data}) => {
            toast.success("foutmelding toegevoegd.")

            setEditableIssue(null);
            setMachine(data.data)
            closePopup();
            // setCurrentIssue(null);
        }).catch((exception) => {
            toast.error("Er ging wat mis met het aanmaken van de foutmelding")
            console.error(exception);
        })
    }

    useEffect(() => {
        // Add on close event listener to reset popup data
        document.addEventListener('close-popup', test);

        // Remove event listener on unmount
        return () => {
            document.removeEventListener('close-popup', test);
        }
    }, []);

    function test({ detail }){
        if (detail.popupID !== 'add-machine-malfunction') return;

        setCurrentIssue(defaultCurrentIssueState)
    }

    return (
        <Popup title='Fout melding toevoegen' popupID='add-machine-malfunction'>
            <form onSubmit={submitIssue}>
                <FancyInput
                    id={"issue"}
                    label={"Storing"}
                    required
                    hideIcon={true}
                    value={currentIssue.reportedIssue}
                    onChange={(e) => {
                        setCurrentIssue((currentIssue) => ({ ...currentIssue, reportedIssue: e.target.value }))
                    }}
                />
                <select
                    id={"statusSelect"}
                    required
                    onChange={(e) => {
                        setCurrentIssue((currentIssue) => ({ ...currentIssue, issueStatus: e.target.value }))
                    }}
                    value={currentIssue?.issueStatus}
                    >
                    {/*{statusOptions}*/}
                    options={statusOptions.map((status) => {
                        return <option key={status} value={status}>{status}</option>
                    })}
                    </select>
                    <FancyInput
                    id={"solution"}
                    label={"Oplossing"}
                    hideIcon={true}
                    description={"Optioneel."}
                    value={currentIssue.issueSolution}
                    onChange={(e) => {
                        setCurrentIssue((currentIssue) => ({ ...currentIssue, issueSolution: e.target.value }))
                    }}
                />
                <Button type={"submit"}>Aanmaken</Button>
            </form>
        </Popup>
    )
}
