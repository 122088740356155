import Popup, {closePopup} from "UI/App/Components/Popup/Popup";
import MaterialPopupContent from "UI/App/Components/Popup/Popups/MaterialPopup/MaterialPopupContent";
import {toast} from "react-toastify";
import {FetchContext} from "App/Strapi/FetchContext";
import {useContext} from "react";
import MaterialUnitMutator from "UI/App/Components/Material/MaterialUnitMutator";

export default function RegisterMaterialPopup({
    defaultMaterialId,
    defaultUnit,
    defaultQuantity,
    defaultWarehouse,
    onClose,
    materialLocked,
}) {
    const { authAxios } = useContext(FetchContext);

    async function onSubmit(materialId, unit, unverifiedQuantity, warehouse) {
        const quantity =  await (new MaterialUnitMutator()).quantityMerchant(unverifiedQuantity,materialId)
        unit = await (new MaterialUnitMutator()).obtainUnitForStorage(materialId);

        if(warehouse === null){
            toast.error('Kies een magazijn!');
            return;
        }

        await authAxios.post(`/logistics/materials/${materialId}/stock/register`, {
            data: {
                unit,
                quantity,
                warehouse,
            }
        }).then(() => {
            toast.success('Materiaal ingeboekt');
            onClose();
            closePopup();
        }).catch((exception) => {
            console.error(exception);
            toast.error(`Materiaal kon niet worden ingeboekt${exception?.response?.status ? ` (${exception?.response?.status})` : ''}`);
        })
    }

    return (
        <Popup
            overflow={true}
            popupID={'registerMaterial'}
            title={'Materiaal inboeken'}
        >
            <MaterialPopupContent
                onSubmit={onSubmit}
                defaultMaterialId={defaultMaterialId}
                defaultUnit={defaultUnit}
                defaultQuantity={defaultQuantity}
                defaultWarehouse={defaultWarehouse}
                maximumQuantity={89999999} // max before strapi commits unalive
                buttonText={'Inboeken'}
                materialLocked={materialLocked}
                displayAllWarehouses={true}
            />
        </Popup>
    )
}
