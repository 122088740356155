import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { FetchContext } from 'App/Strapi/FetchContext';
import { formatNumber } from 'App/Util/format';
import Container from 'UI/App/Components/Container/Container';
import { usePagination } from 'UI/App/Components/Pagination/Pagination';
import { SpinnerCenter } from 'UI/App/Components/Spinner';
import Table from 'UI/App/Components/Table/Table';

export default function Warehouse() {
    const { authAxios } = useContext(FetchContext);
    const navigate = useNavigate();
    const params = useParams();

    const [warehouse, setWarehouse] = useState({ name: null, location: null });
    const [materialTableData, setMaterialTableData] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isTableLoading, setIsTableLoading] = useState(true);

    const {
        currentPage,
        filtering,
        filterQuery,
        paginationStateLoaded,
        paging,
        resultsPerPage,
        setCurrentPage,
        setTotalPages,
        setTotalResults
    } = usePagination({
        storageKey: 'warehouse-materials',
        searchSettings: {
            enabled: true,
            strapiFields: ['[material][quotationNameNL]', '[material][internalSKU]']
        }
    });

    // get warehouse seperately because you can't have pagination when populating stocks
    useEffect(() => {
        if (!params?.id) return;

        const warehouseQuery = stringify({
            fields: ['name', 'location']
        });

        authAxios
            .get(`/logistics/warehouses/${params.id}?${warehouseQuery}`)
            .then(({ data }) => {
                setWarehouse({
                    name: data.data?.name,
                    location: data.data?.location
                });
            })
            .catch((exception) => {
                console.error(exception);
                toast.error('Het magazijn kon niet opgehaald worden.');
            })
            .finally(() => {
                setIsPageLoading(false);
            });
    }, [authAxios, params?.id]);

    // reset table pagination to the first page on mount due to the current page being saved in storage and the same storageKey is used for every warehouse
    // it would cause a table page 4 to be loaded when the current warehouse may not even have 4 table pages
    useEffect(() => {
        if (paginationStateLoaded) setCurrentPage(1);
    }, [paginationStateLoaded, setCurrentPage]);

    // get stock
    useEffect(() => {
        if (!paginationStateLoaded || !params?.id) return;

        setIsTableLoading(true);

        const stockQuery = stringify({
            fields: ['id', 'total', 'reserved', 'unit'],
            populate: {
                material: {
                    fields: ['id', 'quotationNameNL', 'internalSKU']
                }
            },
            sort: ['material.quotationNameNL:asc', 'material.internalSKU:asc'],
            filters: {
                warehouse: {
                    id: {
                        $eq: params.id
                    }
                }
            },
            pagination: {
                page: currentPage,
                pageSize: resultsPerPage
            }
        });

        /**
         * @type {AbortController} A controller object that allows you to abort one or more DOM requests as and when desired.
         * {@link https://developer.mozilla.org/en-US/docs/Web/API/AbortController|MDN Reference} | {@link https://axios-http.com/docs/cancellation|Axois documentation}
         *
         * This IS needed here due to shared warehouse pagination storageKey & forcing page 1 on component mount
         */
        const abortController = new AbortController();

        authAxios
            .get(`/logistics/materials/stock?${stockQuery}&${filterQuery}`, { signal: abortController.signal })
            .then(({ data }) => {
                const tableData = (data.data ?? []).map((stock) => ({
                    attributes: {
                        onClick: () => {
                            navigate(`/calculations/catalog/materials/${stock?.material?.id}`);
                        }
                    },
                    data: [
                        (
                            (stock?.material?.quotationNameNL ?? '') +
                            ' ' +
                            (stock?.material?.internalSKU ? `(${stock.material.internalSKU})` : '')
                        ).trim(),
                        `${formatNumber(stock.available)} ${stock.unit}`,
                        `${formatNumber(stock.reserved)} ${stock.unit}`,
                        `${formatNumber(stock.total)} ${stock.unit}`
                    ]
                }));

                setMaterialTableData(tableData);
                setTotalPages(data.meta.pagination.pageCount);
                setTotalResults(data.meta.pagination.total);
                setIsTableLoading(false);
            })
            .catch((exception) => {
                // skip aborted requests
                if (exception?.config?.signal?.aborted) return;

                setIsTableLoading(false);

                console.error(exception);
                toast.error('De materialen in het magazijn kan niet opgehaald worden.');
            });

        return () => {
            // cancel the pending request
            abortController.abort();
            setIsTableLoading(false);
        };
    }, [
        authAxios,
        currentPage,
        filterQuery,
        navigate,
        paginationStateLoaded,
        params?.id,
        resultsPerPage,
        setTotalPages,
        setTotalResults
    ]);

    if (isPageLoading) {
        return <SpinnerCenter />;
    }

    return (
        <Container style={{ borderRadius: '5px', overflow: 'hidden' }}>
            {filtering}
            <Table
                headers={[`${warehouse?.name}, ${warehouse?.location}`, 'Beschikbaar', 'Gereserveerd', 'Totaal']}
                data={materialTableData}
                padding='s'
                isDataLoading={isTableLoading}
            />
            {paging}
        </Container>
    );
}
