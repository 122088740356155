import WerkderdenPurchaseTab from "UI/App/Partials/Content/Calculations/Template/Tabs/WerkderdenPurchaseTab";
import {useContext} from "react";
import {CalculationProviderContext} from "UI/App/Partials/Content/Calculations/HJMG/CreateHJMGCalculation";

export default function WerkderdenTabHJMG({calculationId, checklistData, purchaseQuoteData}) {
    const { activeTab, isCopy } = useContext(CalculationProviderContext);

    return (
        <div className={'hjmg__calculation__tab'} data-active={activeTab === 'purchase'}>
            <WerkderdenPurchaseTab
                quantities={false}
                checklistData={checklistData}
                activeTab={activeTab}
                calculationId={isCopy ? null : calculationId}
                thirdParty={purchaseQuoteData}
            />
        </div>
    )
}